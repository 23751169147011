@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 0px 15px;
  display: inline-block;
  cursor: pointer;
  -webkit-transition-property: opacity, -webkit-filter;
  transition-property: opacity, -webkit-filter;
  transition-property: opacity, filter;
  transition-property: opacity, filter, -webkit-filter;
  -webkit-transition-duration: 0.25s;
          transition-duration: 0.25s;
  -webkit-transition-timing-function: linear;
          transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible; }
  .hamburger:hover {
    opacity: 1; }

.hamburger-box {
  width: 30px;
  height: 24px;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -2px; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    width: 30px;
    height: 4px;
    background-color: #fff;
    border-radius: 4px;
    position: absolute;
    -webkit-transition-property: -webkit-transform;
    transition-property: -webkit-transform;
    transition-property: transform;
    transition-property: transform, -webkit-transform;
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease; }
  .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block; }
  .hamburger-inner::before {
    top: -10px; }
  .hamburger-inner::after {
    bottom: -10px; }

/*
   * 3DX
   */
.hamburger--3dx .hamburger-box {
  -webkit-perspective: 60px;
          perspective: 60px; }

.hamburger--3dx .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
    -webkit-transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent;
  -webkit-transform: rotateY(180deg);
          transform: rotateY(180deg); }
  .hamburger--3dx.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
            transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DX Reverse
   */
.hamburger--3dx-r .hamburger-box {
  -webkit-perspective: 60px;
          perspective: 60px; }

.hamburger--3dx-r .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
    -webkit-transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent;
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dx-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
            transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY
   */
.hamburger--3dy .hamburger-box {
  -webkit-perspective: 60px;
          perspective: 60px; }

.hamburger--3dy .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
    -webkit-transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent;
  -webkit-transform: rotateX(-180deg);
          transform: rotateX(-180deg); }
  .hamburger--3dy.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dy.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
            transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DY Reverse
   */
.hamburger--3dy-r .hamburger-box {
  -webkit-perspective: 60px;
          perspective: 60px; }

.hamburger--3dy-r .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
    -webkit-transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent;
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dy-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
            transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DXY
   */
.hamburger--3dxy .hamburger-box {
  -webkit-perspective: 60px;
          perspective: 60px; }

.hamburger--3dxy .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
    -webkit-transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent;
  -webkit-transform: rotateX(180deg) rotateY(180deg);
          transform: rotateX(180deg) rotateY(180deg); }
  .hamburger--3dxy.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dxy.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
            transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * 3DXY Reverse
   */
.hamburger--3dxy-r .hamburger-box {
  -webkit-perspective: 60px;
          perspective: 60px; }

.hamburger--3dxy-r .hamburger-inner {
  -webkit-transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1); }
  .hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
    -webkit-transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1), -webkit-transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1); }

.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent;
  -webkit-transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
          transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--3dxy-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
            transform: translate3d(0, -10px, 0) rotate(-45deg); }

/*
   * Arrow
   */
.hamburger--arrow.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(-6px, 0, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(-6px, 0, 0) rotate(-45deg) scale(0.7, 1); }

.hamburger--arrow.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(-6px, 0, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(-6px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Arrow Right
   */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  -webkit-transform: translate3d(6px, 0, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(6px, 0, 0) rotate(45deg) scale(0.7, 1); }

.hamburger--arrow-r.is-active .hamburger-inner::after {
  -webkit-transform: translate3d(6px, 0, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(6px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Alt
   */
.hamburger--arrowalt .hamburger-inner::before {
  -webkit-transition: top 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt .hamburger-inner::after {
  -webkit-transition: bottom 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: translate3d(-6px, -7.5px, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(-6px, -7.5px, 0) rotate(-45deg) scale(0.7, 1);
  -webkit-transition: top 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: translate3d(-6px, 7.5px, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(-6px, 7.5px, 0) rotate(45deg) scale(0.7, 1);
  -webkit-transition: bottom 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Alt Right
   */
.hamburger--arrowalt-r .hamburger-inner::before {
  -webkit-transition: top 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r .hamburger-inner::after {
  -webkit-transition: bottom 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1), -webkit-transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1); }

.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  -webkit-transform: translate3d(6px, -7.5px, 0) rotate(45deg) scale(0.7, 1);
          transform: translate3d(6px, -7.5px, 0) rotate(45deg) scale(0.7, 1);
  -webkit-transition: top 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  -webkit-transform: translate3d(6px, 7.5px, 0) rotate(-45deg) scale(0.7, 1);
          transform: translate3d(6px, 7.5px, 0) rotate(-45deg) scale(0.7, 1);
  -webkit-transition: bottom 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22), -webkit-transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22); }

/*
   * Arrow Turn
   */
.hamburger--arrowturn.is-active .hamburger-inner {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }
  .hamburger--arrowturn.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
            transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1); }
  .hamburger--arrowturn.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
            transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1); }

/*
   * Arrow Turn Right
   */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::before {
    -webkit-transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
            transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1); }
  .hamburger--arrowturn-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
            transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1); }

/*
   * Boring
   */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  -webkit-transition-property: none;
  transition-property: none; }

.hamburger--boring.is-active .hamburger-inner {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }
  .hamburger--boring.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--boring.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }

/*
   * Collapse
   */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-duration: 0.13s;
          transition-duration: 0.13s;
  -webkit-transition-delay: 0.13s;
          transition-delay: 0.13s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse .hamburger-inner::after {
    top: -20px;
    -webkit-transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse .hamburger-inner::before {
    -webkit-transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
          transform: translate3d(0, -10px, 0) rotate(-45deg);
  -webkit-transition-delay: 0.22s;
          transition-delay: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    -webkit-transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Collapse Reverse
   */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-duration: 0.13s;
          transition-duration: 0.13s;
  -webkit-transition-delay: 0.13s;
          transition-delay: 0.13s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--collapse-r .hamburger-inner::after {
    top: -20px;
    -webkit-transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear; }
  .hamburger--collapse-r .hamburger-inner::before {
    -webkit-transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--collapse-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, -10px, 0) rotate(45deg);
          transform: translate3d(0, -10px, 0) rotate(45deg);
  -webkit-transition-delay: 0.22s;
          transition-delay: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--collapse-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    -webkit-transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear; }
  .hamburger--collapse-r.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Elastic
   */
.hamburger--elastic .hamburger-inner {
  top: 2px;
  -webkit-transition-duration: 0.275s;
          transition-duration: 0.275s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
          transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic .hamburger-inner::before {
    top: 10px;
    -webkit-transition: opacity 0.125s 0.275s ease;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic .hamburger-inner::after {
    top: 20px;
    -webkit-transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(135deg);
          transform: translate3d(0, 10px, 0) rotate(135deg);
  -webkit-transition-delay: 0.075s;
          transition-delay: 0.075s; }
  .hamburger--elastic.is-active .hamburger-inner::before {
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -20px, 0) rotate(-270deg);
            transform: translate3d(0, -20px, 0) rotate(-270deg);
    -webkit-transition-delay: 0.075s;
            transition-delay: 0.075s; }

/*
   * Elastic Reverse
   */
.hamburger--elastic-r .hamburger-inner {
  top: 2px;
  -webkit-transition-duration: 0.275s;
          transition-duration: 0.275s;
  -webkit-transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
          transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55); }
  .hamburger--elastic-r .hamburger-inner::before {
    top: 10px;
    -webkit-transition: opacity 0.125s 0.275s ease;
    transition: opacity 0.125s 0.275s ease; }
  .hamburger--elastic-r .hamburger-inner::after {
    top: 20px;
    -webkit-transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55), -webkit-transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55); }

.hamburger--elastic-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(-135deg);
          transform: translate3d(0, 10px, 0) rotate(-135deg);
  -webkit-transition-delay: 0.075s;
          transition-delay: 0.075s; }
  .hamburger--elastic-r.is-active .hamburger-inner::before {
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    opacity: 0; }
  .hamburger--elastic-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -20px, 0) rotate(270deg);
            transform: translate3d(0, -20px, 0) rotate(270deg);
    -webkit-transition-delay: 0.075s;
            transition-delay: 0.075s; }

/*
   * Emphatic
   */
.hamburger--emphatic {
  overflow: hidden; }
  .hamburger--emphatic .hamburger-inner {
    -webkit-transition: background-color 0.125s 0.175s ease-in;
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic .hamburger-inner::before {
      left: 0;
      -webkit-transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
    .hamburger--emphatic .hamburger-inner::after {
      top: 10px;
      right: 0;
      -webkit-transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
  .hamburger--emphatic.is-active .hamburger-inner {
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
    background-color: transparent; }
    .hamburger--emphatic.is-active .hamburger-inner::before {
      left: -60px;
      top: -60px;
      -webkit-transform: translate3d(60px, 60px, 0) rotate(45deg);
              transform: translate3d(60px, 60px, 0) rotate(45deg);
      -webkit-transition: left 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic.is-active .hamburger-inner::after {
      right: -60px;
      top: -60px;
      -webkit-transform: translate3d(-60px, 60px, 0) rotate(-45deg);
              transform: translate3d(-60px, 60px, 0) rotate(-45deg);
      -webkit-transition: right 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Emphatic Reverse
   */
.hamburger--emphatic-r {
  overflow: hidden; }
  .hamburger--emphatic-r .hamburger-inner {
    -webkit-transition: background-color 0.125s 0.175s ease-in;
    transition: background-color 0.125s 0.175s ease-in; }
    .hamburger--emphatic-r .hamburger-inner::before {
      left: 0;
      -webkit-transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
    .hamburger--emphatic-r .hamburger-inner::after {
      top: 10px;
      right: 0;
      -webkit-transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335);
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
      transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in, -webkit-transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335); }
  .hamburger--emphatic-r.is-active .hamburger-inner {
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    -webkit-transition-timing-function: ease-out;
            transition-timing-function: ease-out;
    background-color: transparent; }
    .hamburger--emphatic-r.is-active .hamburger-inner::before {
      left: -60px;
      top: 60px;
      -webkit-transform: translate3d(60px, -60px, 0) rotate(-45deg);
              transform: translate3d(60px, -60px, 0) rotate(-45deg);
      -webkit-transition: left 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }
    .hamburger--emphatic-r.is-active .hamburger-inner::after {
      right: -60px;
      top: 60px;
      -webkit-transform: translate3d(-60px, -60px, 0) rotate(45deg);
              transform: translate3d(-60px, -60px, 0) rotate(45deg);
      -webkit-transition: right 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
      transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1), -webkit-transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1); }

/*
   * Minus
   */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  -webkit-transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear; }

.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  -webkit-transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear; }

.hamburger--minus.is-active .hamburger-inner::before {
  top: 0; }

.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0; }

/*
   * Slider
   */
.hamburger--slider .hamburger-inner {
  top: 2px; }
  .hamburger--slider .hamburger-inner::before {
    top: 10px;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s; }
  .hamburger--slider .hamburger-inner::after {
    top: 20px; }

.hamburger--slider.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
          transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--slider.is-active .hamburger-inner::before {
    -webkit-transform: rotate(-45deg) translate3d(-4.28571px, -6px, 0);
            transform: rotate(-45deg) translate3d(-4.28571px, -6px, 0);
    opacity: 0; }
  .hamburger--slider.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -20px, 0) rotate(-90deg);
            transform: translate3d(0, -20px, 0) rotate(-90deg); }

/*
   * Slider Reverse
   */
.hamburger--slider-r .hamburger-inner {
  top: 2px; }
  .hamburger--slider-r .hamburger-inner::before {
    top: 10px;
    -webkit-transition-property: opacity, -webkit-transform;
    transition-property: opacity, -webkit-transform;
    transition-property: transform, opacity;
    transition-property: transform, opacity, -webkit-transform;
    -webkit-transition-timing-function: ease;
            transition-timing-function: ease;
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s; }
  .hamburger--slider-r .hamburger-inner::after {
    top: 20px; }

.hamburger--slider-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
          transform: translate3d(0, 10px, 0) rotate(-45deg); }
  .hamburger--slider-r.is-active .hamburger-inner::before {
    -webkit-transform: rotate(45deg) translate3d(4.28571px, -6px, 0);
            transform: rotate(45deg) translate3d(4.28571px, -6px, 0);
    opacity: 0; }
  .hamburger--slider-r.is-active .hamburger-inner::after {
    -webkit-transform: translate3d(0, -20px, 0) rotate(90deg);
            transform: translate3d(0, -20px, 0) rotate(90deg); }

/*
   * Spin
   */
.hamburger--spin .hamburger-inner {
  -webkit-transition-duration: 0.22s;
          transition-duration: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin .hamburger-inner::before {
    -webkit-transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin .hamburger-inner::after {
    -webkit-transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  -webkit-transform: rotate(225deg);
          transform: rotate(225deg);
  -webkit-transition-delay: 0.12s;
          transition-delay: 0.12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    -webkit-transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spin Reverse
   */
.hamburger--spin-r .hamburger-inner {
  -webkit-transition-duration: 0.22s;
          transition-duration: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spin-r .hamburger-inner::before {
    -webkit-transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in; }
  .hamburger--spin-r .hamburger-inner::after {
    -webkit-transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-225deg);
          transform: rotate(-225deg);
  -webkit-transition-delay: 0.12s;
          transition-delay: 0.12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spin-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    -webkit-transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out; }
  .hamburger--spin-r.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Spring
   */
.hamburger--spring .hamburger-inner {
  top: 2px;
  -webkit-transition: background-color 0s 0.13s linear;
  transition: background-color 0s 0.13s linear; }
  .hamburger--spring .hamburger-inner::before {
    top: 10px;
    -webkit-transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring .hamburger-inner::after {
    top: 20px;
    -webkit-transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring.is-active .hamburger-inner {
  -webkit-transition-delay: 0.22s;
          transition-delay: 0.22s;
  background-color: transparent; }
  .hamburger--spring.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 10px, 0) rotate(45deg);
            transform: translate3d(0, 10px, 0) rotate(45deg); }
  .hamburger--spring.is-active .hamburger-inner::after {
    top: 0;
    -webkit-transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    -webkit-transform: translate3d(0, 10px, 0) rotate(-45deg);
            transform: translate3d(0, 10px, 0) rotate(-45deg); }

/*
   * Spring Reverse
   */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  -webkit-transition-duration: 0.13s;
          transition-duration: 0.13s;
  -webkit-transition-delay: 0s;
          transition-delay: 0s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--spring-r .hamburger-inner::after {
    top: -20px;
    -webkit-transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
    transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear; }
  .hamburger--spring-r .hamburger-inner::before {
    -webkit-transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spring-r.is-active .hamburger-inner {
  -webkit-transform: translate3d(0, -10px, 0) rotate(-45deg);
          transform: translate3d(0, -10px, 0) rotate(-45deg);
  -webkit-transition-delay: 0.22s;
          transition-delay: 0.22s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--spring-r.is-active .hamburger-inner::after {
    top: 0;
    opacity: 0;
    -webkit-transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear;
    transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear; }
  .hamburger--spring-r.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -webkit-transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand
   */
.hamburger--stand .hamburger-inner {
  -webkit-transition: background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand .hamburger-inner::before {
    -webkit-transition: top 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand .hamburger-inner::after {
    -webkit-transition: bottom 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand.is-active .hamburger-inner {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  background-color: transparent;
  -webkit-transition: background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: top 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: bottom 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Stand Reverse
   */
.hamburger--stand-r .hamburger-inner {
  -webkit-transition: background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear, -webkit-transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand-r .hamburger-inner::before {
    -webkit-transition: top 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--stand-r .hamburger-inner::after {
    -webkit-transition: bottom 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--stand-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
  background-color: transparent;
  -webkit-transition: background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear, -webkit-transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand-r.is-active .hamburger-inner::before {
    top: 0;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-transition: top 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--stand-r.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transition: bottom 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Squeeze
   */
.hamburger--squeeze .hamburger-inner {
  -webkit-transition-duration: 0.075s;
          transition-duration: 0.075s;
  -webkit-transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
          transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }
  .hamburger--squeeze .hamburger-inner::before {
    -webkit-transition: top 0.075s 0.12s ease, opacity 0.075s ease;
    transition: top 0.075s 0.12s ease, opacity 0.075s ease; }
  .hamburger--squeeze .hamburger-inner::after {
    -webkit-transition: bottom 0.075s 0.12s ease, -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.12s ease, -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--squeeze.is-active .hamburger-inner {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transition-delay: 0.12s;
          transition-delay: 0.12s;
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
          transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  .hamburger--squeeze.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0;
    -webkit-transition: top 0.075s ease, opacity 0.075s 0.12s ease;
    transition: top 0.075s ease, opacity 0.075s 0.12s ease; }
  .hamburger--squeeze.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg);
    -webkit-transition: bottom 0.075s ease, -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s ease, -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
    transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1); }

/*
   * Vortex
   */
.hamburger--vortex .hamburger-inner {
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
    -webkit-transition-duration: 0s;
            transition-duration: 0s;
    -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear; }
  .hamburger--vortex .hamburger-inner::before {
    -webkit-transition-property: top, opacity;
    transition-property: top, opacity; }
  .hamburger--vortex .hamburger-inner::after {
    -webkit-transition-property: bottom, -webkit-transform;
    transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform;
    transition-property: bottom, transform, -webkit-transform; }

.hamburger--vortex.is-active .hamburger-inner {
  -webkit-transform: rotate(765deg);
          transform: rotate(765deg);
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
    -webkit-transition-delay: 0s;
            transition-delay: 0s; }
  .hamburger--vortex.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg); }

/*
   * Vortex Reverse
   */
.hamburger--vortex-r .hamburger-inner {
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
    -webkit-transition-duration: 0s;
            transition-duration: 0s;
    -webkit-transition-delay: 0.1s;
            transition-delay: 0.1s;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear; }
  .hamburger--vortex-r .hamburger-inner::before {
    -webkit-transition-property: top, opacity;
    transition-property: top, opacity; }
  .hamburger--vortex-r .hamburger-inner::after {
    -webkit-transition-property: bottom, -webkit-transform;
    transition-property: bottom, -webkit-transform;
    transition-property: bottom, transform;
    transition-property: bottom, transform, -webkit-transform; }

.hamburger--vortex-r.is-active .hamburger-inner {
  -webkit-transform: rotate(-765deg);
          transform: rotate(-765deg);
  -webkit-transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
          transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1); }
  .hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
    -webkit-transition-delay: 0s;
            transition-delay: 0s; }
  .hamburger--vortex-r.is-active .hamburger-inner::before {
    top: 0;
    opacity: 0; }
  .hamburger--vortex-r.is-active .hamburger-inner::after {
    bottom: 0;
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }

/* Colors */
/* Z indexes */
/* Widths */
.contents {
  display: inline-block;
  padding: 0 15px; }

.fullWidthThis {
  width: 100% !important; }

.old, .old body {
  /* loader container */
  /* loader anim */
  background-color: #ECF0F5;
  background-attachment: fixed;
  font-family: "Calibri", Arial;
  font-size: 14px;
  line-height: 1.2em;
  color: #222d32;
  -webkit-font-smoothing: antialiased;
  /* Buttons */ }
  .old #overlay-container {
    visibility: visible; }
    .old #overlay-container.hide-overlay {
      visibility: hidden; }
      .old #overlay-container.hide-overlay .overlay-background,
      .old #overlay-container.hide-overlay .overlay-content {
        opacity: 0; }
    .old #overlay-container .overlay-background {
      z-index: 10000;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.8);
      opacity: 1;
      -webkit-transition: all 0.3s linear;
      transition: all 0.3s linear; }
    .old #overlay-container .overlay-content {
      position: fixed;
      z-index: 10001;
      text-align: center;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      -webkit-transition: all 0.5s linear;
      transition: all 0.5s linear; }
  .old .sk-cube-grid {
    width: 120px;
    height: 120px;
    -webkit-animation: rotate360 10s infinite linear;
    animation: rotate360 10s infinite linear; }
  .old .sk-cube-grid .sk-cube {
    width: 38px;
    height: 38px;
    margin: 1px;
    float: left;
    background-color: #2762AA;
    -webkit-animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out;
    animation: sk-cubeGridScaleDelay 1.3s infinite ease-in-out; }
  .old .sk-cube-grid .sk-cube1 {
    -webkit-box-shadow: 0 0 25px rgba(255, 255, 255, 0.1), inset 0 0 3px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.1), inset 0 0 3px rgba(0, 0, 0, 0.6);
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s; }
  .old .sk-cube-grid .sk-cube2 {
    -webkit-box-shadow: 0 0 25px rgba(255, 255, 255, 0.1), inset 0 0 3px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.1), inset 0 0 3px rgba(0, 0, 0, 0.6);
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s; }
  .old .sk-cube-grid .sk-cube3 {
    -webkit-box-shadow: 0 0 25px rgba(255, 255, 255, 0.1), inset 0 0 3px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.1), inset 0 0 3px rgba(0, 0, 0, 0.6);
    -webkit-animation-delay: 0.4s;
    animation-delay: 0.4s; }
  .old .sk-cube-grid .sk-cube4 {
    -webkit-box-shadow: 0 0 25px rgba(255, 255, 255, 0.1), inset 0 0 3px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.1), inset 0 0 3px rgba(0, 0, 0, 0.6);
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s; }
  .old .sk-cube-grid .sk-cube5 {
    -webkit-box-shadow: 0 0 25px rgba(255, 255, 255, 0.1), inset 0 0 3px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.1), inset 0 0 3px rgba(0, 0, 0, 0.6);
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s; }
  .old .sk-cube-grid .sk-cube6 {
    -webkit-box-shadow: 0 0 25px rgba(255, 255, 255, 0.1), inset 0 0 3px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.1), inset 0 0 3px rgba(0, 0, 0, 0.6);
    -webkit-animation-delay: 0.3s;
    animation-delay: 0.3s; }
  .old .sk-cube-grid .sk-cube7 {
    background-color: #2762AA;
    -webkit-box-shadow: 0 0 25px rgba(255, 255, 255, 0.1), inset 0 0 3px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.1), inset 0 0 3px rgba(0, 0, 0, 0.6);
    -webkit-animation-delay: 0s;
    animation-delay: 0s; }
  .old .sk-cube-grid .sk-cube8 {
    -webkit-box-shadow: 0 0 25px rgba(255, 255, 255, 0.1), inset 0 0 3px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.1), inset 0 0 3px rgba(0, 0, 0, 0.6);
    -webkit-animation-delay: 0.1s;
    animation-delay: 0.1s; }
  .old .sk-cube-grid .sk-cube9 {
    -webkit-box-shadow: 0 0 25px rgba(255, 255, 255, 0.1), inset 0 0 3px rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.1), inset 0 0 3px rgba(0, 0, 0, 0.6);
    -webkit-animation-delay: 0.2s;
    animation-delay: 0.2s; }
  .old .swal2-overlay {
    z-index: 20000000 !important; }
  .old .swal2-container,
  .old .swal2-modal {
    z-index: 20000001 !important; }
  .old .swal2-content p {
    text-align: justify;
    padding: 6px; }
  .old .swal2-icon.swal2-info {
    color: #3174A8;
    border-color: #3174A8; }
  .old .swal2-icon.swal2-warning {
    color: #EC9B00;
    border-color: #EC9B00; }
  .old .swal2-icon.swal2-error {
    border-color: #CA341B; }
    .old .swal2-icon.swal2-error [class^='swal2-x-mark-line'] {
      background-color: #CA341B; }
  .old .swal2-popup .swal2-styled {
    border: 1px solid lightgray; }
  .old .swal2-confirm {
    outline: none !important;
    background-color: #3174A8 !important;
    color: white !important;
    padding: 0.5em 1em !important;
    border-radius: 6px !important;
    -webkit-transition: background-color 0.2s linear, color 0.2s linear;
    transition: background-color 0.2s linear, color 0.2s linear;
    min-width: 100px !important;
    margin: 2px;
    -webkit-box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1) inset !important;
    box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1) inset !important;
    border-style: solid !important;
    border-width: 0px 0px 3px !important;
    text-shadow: 0px 1px 0px black;
    position: relative; }
    .old .swal2-confirm:active {
      border: none; }
    .old .swal2-confirm:hover {
      background-color: #3783d5 !important; }
  .old .swal2-cancel {
    outline: none !important;
    background-color: #CA341B !important;
    color: white !important;
    padding: 0.5em 1em !important;
    border-radius: 6px !important;
    -webkit-transition: background-color 0.2s linear, color 0.2s linear;
    transition: background-color 0.2s linear, color 0.2s linear;
    min-width: 100px !important;
    margin: 2px;
    -webkit-box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1) inset !important;
    box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1) inset !important;
    border-style: solid !important;
    border-width: 0px 0px 3px !important;
    text-shadow: 0px 1px 0px black;
    position: relative; }
    .old .swal2-cancel:active {
      border: none; }
    .old .swal2-cancel:hover {
      background-color: #e44d34 !important; }
  .old .e-monthappointment {
    height: 1.75em; }
  .old .e-header .e-text {
    line-height: 27px; }
  .old .e-checkbox-wrapper input {
    display: none; }
  .old .slim-grid.e-grid .e-rowcell,
  .old .slim-grid.e-grid .e-headercell {
    padding: 0.5em 0.7em !important; }
  .old .slim-grid.e-grid .e-summarycell {
    padding: 0.5em 0.25em !important; }
  .old .orientationcss .e-headercelldiv {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
    width: 250px !important;
    height: 250px !important;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .old .e-stackedheadercelldiv {
    padding: 5px 0 !important; }
  .old .e-headercelldiv {
    padding: 5px 0 !important; }
  .old .e-monthappointment {
    width: 95% !important; }
    .old .e-monthappointment > div, .old body .e-monthappointment > div {
      padding-left: 7px; }
  .old .e-input {
    padding-left: 15px; }
  .old .e-treeview .e-ul {
    padding-left: 0; }
  .old .e-default {
    background: inherit; }
  .old .e-pager .e-prevpagedisabled,
  .old .e-pager .e-prevpage,
  .old .e-pager .e-nextpage,
  .old .e-pager .e-nextpagedisabled,
  .old .e-pager .e-lastpagedisabled,
  .old .e-pager .e-lastpage,
  .old .e-pager .e-firstpage,
  .old .e-pager .e-firstpagedisabled {
    background-color: inherit; }
  .old .e-pager .e-pagercontainer {
    background-color: inherit; }
  .old .e-pager .e-prevpagedisabled,
  .old .e-pager .e-prevpage,
  .old .e-pager .e-nextpage,
  .old .e-pager .e-nextpagedisabled,
  .old .e-pager .e-lastpagedisabled,
  .old .e-pager .e-lastpage,
  .old .e-pager .e-firstpage,
  .old .e-pager .e-firstpagedisabled {
    background-color: inherit; }
  .old .e-pager .e-prevpagedisabled,
  .old .e-pager .e-prevpage,
  .old .e-pager .e-nextpage,
  .old .e-pager .e-nextpagedisabled,
  .old .e-pager .e-lastpagedisabled,
  .old .e-pager .e-lastpage,
  .old .e-pager .e-firstpage,
  .old .e-pager .e-firstpagedisabled {
    background-color: inherit; }
  .old .e-pager a:not(.e-currentitem):hover {
    color: inherit; }
  .old .e-pager a.e-currentitem:hover {
    color: white; }
  .old .e-ddl.e-input-group:not(.e-rtl) .e-input-group-icon {
    border: 0; }
  .old .e-grid .e-rowcell.e-updatedtd.e-active {
    color: #333; }
  .old td#annualseason {
    padding: 3px !important; }
    .old td#annualseason input {
      text-align: center; }
  .old .e-workcells .max-size-workcells {
    height: 100%; }
  .old .frozenfix .e-frozenheaderdiv .e-headercell {
    height: 150px !important; }
  .old .e-chkbox-wrap div > span, .old .e-chkbox-wrap .e-button:hover div > span, .old .e-button:hover .e-chkbox-wrap div > span,
  .old .e-radiobtn-wrap > span,
  .old body .e-radiobtn-wrap > span,
  .old .e-button:hover .e-radiobtn-wrap > span,
  .old .e-button:hover body .e-radiobtn-wrap > span {
    z-index: 0 !important; }
  .old .e-uploadbox {
    height: auto !important;
    width: -webkit-fit-content !important;
    width: -moz-fit-content !important;
    width: fit-content !important; }
    .old .e-uploadbox .e-inputbtn {
      color: white !important; }
    .old .e-uploadbox .e-selectpart {
      background-color: #3174A8 !important;
      color: white !important;
      padding: 0.5em 1em !important;
      border-radius: 6px !important;
      -webkit-transition: background-color 0.2s linear, color 0.2s linear;
      transition: background-color 0.2s linear, color 0.2s linear;
      min-width: 100px !important;
      margin: 2px;
      -webkit-box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1) inset !important;
      box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1) inset !important;
      border-style: solid !important;
      border-width: 0px 0px 3px !important;
      text-shadow: 0px 1px 0px black;
      position: relative; }
      .old .e-uploadbox .e-selectpart:active {
        border: none; }
      .old .e-uploadbox .e-selectpart:hover {
        background-color: #3783d5 !important; }
  .old .e-dropdownlist {
    padding: 0 1em; }
  .old .gridheaderverticalcenter {
    vertical-align: bottom;
    text-align: center; }
  .old .rotate90degreegridheader {
    vertical-align: bottom; }
    .old .rotate90degreegridheader .e-headercelldiv {
      -webkit-transform: rotate(-90deg);
      transform: rotate(-90deg);
      -webkit-transform-origin: 0 0;
      transform-origin: 0 0;
      text-align: center !important;
      width: 120px !important;
      height: 14px !important;
      text-overflow: unset !important; }
      .old .rotate90degreegridheader .e-headercelldiv[data-ej-mappingname="SzuletesiDatum"] {
        padding-top: 0.75em; }
  .old .selected-row {
    background-color: #5EABDE !important;
    color: green; }
  .old .rotate90degreegridheader2 .e-headercelldiv {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: 50% 50% !important;
    transform-origin: 50% 50% !important;
    text-align: center !important;
    margin-bottom: 0 !important;
    width: 150px !important;
    height: 150px !important; }
  .old .rotate90degreegridheader3 .e-headercelldiv {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-align: left !important;
    margin-bottom: 0 !important;
    width: 150px !important;
    height: 150px !important;
    padding-left: 2em; }
  .old .rotate90degreegridheader4 .e-headercelldiv {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    text-align: left !important;
    margin-bottom: 20px !important;
    width: 150px !important;
    height: 130px !important; }
  .old button#done,
  .old button#Schedule1alertcancel {
    outline: none !important;
    background-color: #3174A8 !important;
    color: white !important;
    padding: 0.5em 1em !important;
    border-radius: 6px !important;
    -webkit-transition: background-color 0.2s linear, color 0.2s linear;
    transition: background-color 0.2s linear, color 0.2s linear;
    min-width: 100px !important;
    margin: 2px;
    -webkit-box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1) inset !important;
    box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1) inset !important;
    border-style: solid !important;
    border-width: 0px 0px 3px !important;
    text-shadow: 0px 1px 0px black;
    position: relative; }
    .old button#done:active,
    .old button#Schedule1alertcancel:active {
      border: none; }
    .old button#done:hover,
    .old button#Schedule1alertcancel:hover {
      background-color: #3783d5 !important; }
  .old button#cancel,
  .old button#Schedule1alertok {
    outline: none !important;
    background-color: #CA341B !important;
    color: white !important;
    padding: 0.5em 1em !important;
    border-radius: 6px !important;
    -webkit-transition: background-color 0.2s linear, color 0.2s linear;
    transition: background-color 0.2s linear, color 0.2s linear;
    min-width: 100px !important;
    margin: 2px;
    -webkit-box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1) inset;
    box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1) inset;
    border-style: solid;
    border-width: 0px 0px 3px;
    text-shadow: 0px 1px 0px black;
    position: relative; }
    .old button#cancel:active,
    .old button#Schedule1alertok:active {
      border: none; }
    .old button#cancel:hover,
    .old button#Schedule1alertok:hover {
      background-color: #e44d34 !important; }
  .old .e-title i {
    font-size: 1.2em; }
  .old .scroller-fix > .e-content, .old body .scroller-fix > .e-content {
    overflow: auto;
    width: 100% !important; }
  .old .e-menu .e-list.e-hidden-item {
    display: none !important; }
  .old .e-treeview-wrap {
    border-color: #c8c8c8 !important;
    border: 1px solid;
    -webkit-box-sizing: border-box !important;
    box-sizing: border-box !important;
    overflow: hidden; }
  .old .e-atc-popup ul li {
    overflow: hidden; }
  .old .e-button span, .old .e-button:hover span {
    color: #FFFFFF; }
  .old .e-grid .e-icon-calender::before {
    width: 15px !important;
    content: '\e7b5'; }
  .old .e-rowcell .e-rating .e-ul {
    width: 190px !important; }
  .old .sidebar {
    width: 230px;
    float: left;
    color: #b8c7ce;
    position: relative;
    height: 100%; }
    .old .sidebar a {
      color: #b8c7ce;
      text-decoration: none;
      display: block;
      -webkit-transition: 0.3s all linear;
      transition: 0.3s all linear; }
      .old .sidebar a:hover {
        color: #FFFFFF; }
    .old .sidebar .sidebar-filler {
      z-index: 1;
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      background: #222d32;
      width: 230px; }
    .old .sidebar .sidebar-dropdown {
      display: none; }
      .old .sidebar .sidebar-dropdown a {
        background-color: #222d32;
        padding: 0.5em;
        text-transform: uppercase;
        text-align: center;
        border-top: 1px solid #676767;
        border-bottom: 1px solid #676767; }
    .old .sidebar .sidebar-inner {
      width: 100%;
      position: relative;
      z-index: 60;
      height: calc(100% - 64px);
      overflow-x: hidden;
      overflow-y: auto;
      display: block; }
      .old .sidebar .sidebar-inner ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        -webkit-transition: 0.3s all linear;
        transition: 0.3s all linear; }
        .old .sidebar .sidebar-inner ul li {
          list-style-type: none;
          -webkit-animation: swing-in-top-fwd 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
          animation: swing-in-top-fwd 0.75s cubic-bezier(0.175, 0.885, 0.32, 1.275) both; }
        .old .sidebar .sidebar-inner ul.navi li ul,
        .old .sidebar .sidebar-inner ul.navi li ul li ul {
          display: none;
          background: #1F282C; }
        .old .sidebar .sidebar-inner ul.navi li a {
          padding: 12px 5px 12px 15px;
          border-bottom: 1px solid #181f23;
          background-color: #222d32; }
          .old .sidebar .sidebar-inner ul.navi li a:hover {
            background-color: #3783d5; }
          .old .sidebar .sidebar-inner ul.navi li a .glyphicon-menu-right {
            -webkit-transition: 0.2s all linear;
            transition: 0.2s all linear; }
            .old .sidebar .sidebar-inner ul.navi li a .glyphicon-menu-right.open {
              -webkit-transform: rotateZ(90deg);
              transform: rotateZ(90deg); }
        .old .sidebar .sidebar-inner ul.navi li > ul > li > a {
          background-color: transparent;
          padding-left: 30px;
          padding-right: 15px;
          border-bottom: 1px solid #222d32; }
        .old .sidebar .sidebar-inner ul.navi li > ul > li > ul > li > a {
          background-color: transparent;
          padding: 10px 15px 10px 50px;
          border-bottom: 1px solid #222; }
        .old .sidebar .sidebar-inner ul.navi li.open > ul,
        .old .sidebar .sidebar-inner ul.navi li.open > ul > li.open > ul {
          display: block; }
        .old .sidebar .sidebar-inner ul.navi li.open > a,
        .old .sidebar .sidebar-inner ul.navi li.current > a,
        .old .sidebar .sidebar-inner ul.navi li.open > ul > li.current > a,
        .old .sidebar .sidebar-inner ul.navi li.open > ul > li.open > a,
        .old .sidebar .sidebar-inner ul.navi li.open > ul > li.open > ul > li.current > a {
          background-color: #266AB3;
          color: #FFFFFF; }
      .old .sidebar .sidebar-inner #footer {
        position: absolute;
        left: 0;
        bottom: 0;
        text-align: center;
        width: 230px;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        z-index: -1; }
        .old .sidebar .sidebar-inner #footer a {
          margin: 5px; }
  .old .sideMenuOpenedButton {
    -webkit-transition: all 0.5s;
    /* Safari */
    transition: all 0.5s;
    position: absolute;
    border-radius: 0;
    top: 0;
    right: 1px;
    width: 50px;
    height: 40px;
    opacity: 0; }
  .old .sideMenuClosedButton {
    -webkit-transition: all 0.5s;
    /* Safari */
    transition: all 0.5s;
    position: absolute;
    border-radius: 0 0 5px 0;
    top: 0;
    right: -100px;
    width: 50px;
    height: 40px;
    -webkit-transition-delay: 0.5s;
    transition-delay: 0.5s; }
  .old .sideMenuOpened {
    left: 0 !important;
    -webkit-transition: all 0.75s;
    /* Safari */
    transition: all 0.75s; }
  .old .sideMenuClosed {
    left: -280px !important;
    -webkit-transition: all 0.75s;
    /* Safari */
    transition: all 0.75s; }
  .old .sideMenuOpenedContent .mainbar {
    margin-left: 230px !important;
    -webkit-transition: all 0.75s;
    /* Safari */
    transition: all 0.75s; }
  .old .sideMenuClosedContent .mainbar {
    margin-left: 0 !important;
    -webkit-transition: all 0.75s;
    /* Safari */
    transition: all 0.75s; }
  .old .sideMenuOpened .sidebar-filler {
    left: 0 !important;
    -webkit-transition: all 0.75s;
    /* Safari */
    transition: all 0.75s; }
  .old .sideMenuClosed .sidebar-filler {
    left: -280px !important;
    -webkit-transition: all 0.75s;
    /* Safari */
    transition: all 0.75s; }
  .old .navbar {
    height: 64px;
    margin: 0;
    padding: 0; }
    .old .navbar .dropdown-menu {
      margin: 3px 0 0;
      position: static;
      min-width: auto; }
    .old .navbar .nav .dropdown {
      font-size: 1rem; }
      .old .navbar .nav .dropdown.open > a,
      .old .navbar .nav .dropdown.open > a:hover,
      .old .navbar .nav .dropdown.open > a:focus {
        background-color: transparent; }
      .old .navbar .nav .dropdown.open .dropdown-menu {
        white-space: nowrap;
        -webkit-transform-style: preserve-3d;
        transform-style: preserve-3d;
        -webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
        animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both; }
        .old .navbar .nav .dropdown.open .dropdown-menu > li > a {
          padding: 0.75em 1.25em; }
          .old .navbar .nav .dropdown.open .dropdown-menu > li > a:hover, .old .navbar .nav .dropdown.open .dropdown-menu > li > a:focus, .old .navbar .nav .dropdown.open .dropdown-menu > li > a:active {
            background-color: #3174A8;
            color: #FFFFFF; }
        .old .navbar .nav .dropdown.open .dropdown-menu .divider {
          background-color: #e5e5e5;
          height: 1px;
          margin: 0; }
    .old .navbar .nav > li > a {
      color: #FFFFFF; }
      .old .navbar .nav > li > a:hover, .old .navbar .nav > li > a:focus {
        background-color: inherit; }
      .old .navbar .nav > li > a .img-rounded.profile-image {
        width: 2.5rem;
        height: 2.5rem;
        margin: 0 5px 0 0; }
    .old .navbar i {
      margin-right: 3px; }
    .old .navbar.navbar-inverse {
      background-color: #222d32;
      border-color: #181f23; }
    .old .navbar .navbar-brand {
      padding: 0;
      margin: 0;
      height: 4rem; }
      .old .navbar .navbar-brand .brand-title {
        line-height: 1.2em;
        color: #FFFFFF; }
  .old .disabledMenuItem {
    background-color: #676767 !important;
    pointer-events: none; }
    .old .disabledMenuItem:hover {
      background-color: #676767 !important; }
    .old .disabledMenuItem a {
      background-color: #676767 !important; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes loginShow {
  0% {
    top: 60%;
    opacity: 0; }
  100% {
    top: 50%;
    opacity: 1; } }

@keyframes loginShow {
  0% {
    top: 60%;
    opacity: 0; }
  100% {
    top: 50%;
    opacity: 1; } }

@-webkit-keyframes img-appear {
  0% {
    width: 0;
    opacity: 0; }
  100% {
    width: 50px;
    opacity: 1; } }

@keyframes img-appear {
  0% {
    width: 0;
    opacity: 0; }
  100% {
    width: 50px;
    opacity: 1; } }

@-webkit-keyframes img-disappear {
  0% {
    width: 50px;
    opacity: 1; }
  100% {
    width: 0;
    opacity: 0; } }

@keyframes img-disappear {
  0% {
    width: 50px;
    opacity: 1; }
  100% {
    width: 0;
    opacity: 0; } }

@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0; }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1; } }

@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
    transform: rotateX(-100deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 0; }
  100% {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1; } }

@-webkit-keyframes shake-top {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0; }
  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg); }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg); }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg); }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg); }
  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg); } }

@keyframes shake-top {
  0%,
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transform-origin: 50% 0;
    transform-origin: 50% 0; }
  10% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg); }
  20%,
  40%,
  60% {
    -webkit-transform: rotate(-4deg);
    transform: rotate(-4deg); }
  30%,
  50%,
  70% {
    -webkit-transform: rotate(4deg);
    transform: rotate(4deg); }
  80% {
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg); }
  90% {
    -webkit-transform: rotate(2deg);
    transform: rotate(2deg); } }

@-webkit-keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  50% {
    -webkit-transform: translateY(2.5%);
    transform: translateY(2.5%); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes bounce {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  50% {
    -webkit-transform: translateY(2.5%);
    transform: translateY(2.5%); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1); }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1); } }

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    -webkit-transform: scale3D(1, 1, 1);
    transform: scale3D(1, 1, 1); }
  35% {
    -webkit-transform: scale3D(0, 0, 1);
    transform: scale3D(0, 0, 1); } }

@-webkit-keyframes rotate360 {
  0% {
    transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg); }
  100% {
    transform: rotateZ(360deg);
    -webkit-transform: rotateZ(360deg); } }

@keyframes rotate360 {
  0% {
    transform: rotateZ(0deg);
    -webkit-transform: rotateZ(0deg); }
  100% {
    transform: rotateZ(360deg);
    -webkit-transform: rotateZ(360deg); } }
  .old .mainbar.borderless {
    /* Style the tab */
    /* Style the buttons inside the tab */
    /* Change background color of buttons on hover */
    /* Create an active/current tablink class */ }
    .old .mainbar.borderless .mobile-background {
      background-image: url("../images/MobBg.png");
      background-position: bottom;
      background-attachment: fixed;
      background-repeat: no-repeat;
      background-size: cover;
      top: 0;
      min-width: 100%;
      min-height: 50vh;
      overflow: hidden; }
      .old .mainbar.borderless .mobile-background.empty-space-content {
        min-height: 30vh;
        border: 0.4px solid #fff; }
    .old .mainbar.borderless .row {
      text-align: center;
      margin: 0 !important;
      padding: 0.5em 0;
      width: 100%;
      border: 0.4px solid #fff; }
    .old .mainbar.borderless .header-content-container {
      position: absolute;
      background-color: rgba(125, 179, 224, 0.6);
      border: 0.4px solid #fff;
      text-align: end;
      min-width: 35%;
      top: 15%;
      color: #ffffff; }
      .old .mainbar.borderless .header-content-container .display-1 {
        padding: 0.2em; }
    .old .mainbar.borderless .description-content-container {
      background-color: rgba(125, 179, 224, 0.6);
      text-align: end;
      color: #ffffff;
      width: 100%;
      margin-bottom: 1.8em;
      border: 0.4px solid #fff; }
    .old .mainbar.borderless .col-md-12 {
      margin: 0;
      padding: 0;
      display: inline-block; }
      .old .mainbar.borderless .col-md-12.introSlide {
        display: block;
        background-color: rgba(255, 255, 255, 0) !important;
        height: 100%;
        border: 0.4px solid #fff; }
      .old .mainbar.borderless .col-md-12.infoSlide {
        background-color: rgba(31, 37, 51, 0.65) !important;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex; }
        .old .mainbar.borderless .col-md-12.infoSlide img {
          padding: 1em;
          max-height: 400px;
          -webkit-transition: all 04s ease;
          transition: all 04s ease;
          -webkit-animation: bounce 4s infinite;
          animation: bounce 4s infinite; }
        .old .mainbar.borderless .col-md-12.infoSlide strong {
          color: #063c94; }
        .old .mainbar.borderless .col-md-12.infoSlide .col-sm-12,
        .old .mainbar.borderless .col-md-12.infoSlide .col-md-6,
        .old .mainbar.borderless .col-md-12.infoSlide .col-lg-7 {
          padding: 0;
          margin-top: 4em; }
        @media (max-width: 991px) {
          .old .mainbar.borderless .col-md-12.infoSlide .col-sm-12,
          .old .mainbar.borderless .col-md-12.infoSlide .col-md-6,
          .old .mainbar.borderless .col-md-12.infoSlide .col-lg-7 {
            padding: 1em;
            margin-top: 1em; } }
        .old .mainbar.borderless .col-md-12.infoSlide .InfoScanMe {
          background: #d3ff88;
          position: relative;
          bottom: 0;
          right: 0;
          width: 100px; }
          .old .mainbar.borderless .col-md-12.infoSlide .InfoScanMe img {
            -webkit-transition: none !important;
            transition: none !important;
            -webkit-animation: none !important;
            animation: none !important;
            height: auto;
            width: 100px;
            margin: 10px; }
          .old .mainbar.borderless .col-md-12.infoSlide .InfoScanMe .display-3 {
            position: center; }
      .old .mainbar.borderless .col-md-12.darkLight {
        display: block;
        background: linear-gradient(120deg, rgba(0, 0, 0, 0.8) 47.1%, rgba(38, 107, 179, 0.6) 52.1%); }
      .old .mainbar.borderless .col-md-12.downloadSlide {
        display: block !important;
        background-color: rgba(31, 37, 51, 0.85) !important; }
        .old .mainbar.borderless .col-md-12.downloadSlide .display-1 {
          padding: 0; }
        .old .mainbar.borderless .col-md-12.downloadSlide div {
          display: inline-block;
          margin: 15px 20px; }
          .old .mainbar.borderless .col-md-12.downloadSlide div img {
            max-height: 55px; }
    .old .mainbar.borderless .list-type2 {
      margin-right: 2.8em;
      margin-left: auto; }
    .old .mainbar.borderless .list-type2 ol {
      counter-reset: li;
      list-style: none;
      *list-style: decimal;
      font-size: 15px;
      font-family: 'Raleway', sans-serif;
      padding: 0;
      margin-bottom: 1em; }
    .old .mainbar.borderless .list-type2 ol ol {
      margin: 1.4em; }
    .old .mainbar.borderless .list-type2 li {
      margin-bottom: 1em; }
    .old .mainbar.borderless .list-type2 a {
      position: relative;
      display: block;
      padding: .4em;
      margin: 1em;
      background: #74acfc;
      color: #444;
      text-decoration: none; }
    .old .mainbar.borderless .list-type2 a:hover,
    .old .mainbar.borderless a:focus {
      background: #74acfc;
      text-decoration: none;
      border: .1em solid #fff;
      -webkit-transform: scale(1.1);
      transform: scale(1.1); }
    .old .mainbar.borderless .list-type2 a:after {
      content: counter(li);
      counter-increment: li;
      position: absolute;
      left: 100%;
      top: 50%;
      margin-top: -1.3em;
      background: #74acfc;
      height: 2.68em;
      width: 2em;
      line-height: 2em;
      border: .3em solid #fff;
      text-align: center;
      font-weight: bold;
      color: #FFF; }
    .old .mainbar.borderless .display-1 {
      font-size: 2.4em;
      padding: 0;
      font-family: inherit;
      font-weight: 300;
      line-height: 1.3;
      color: #fff;
      display: block;
      margin: 0.3em 0.8em 0.3em 0.3em; }
    .old .mainbar.borderless .display-2 {
      font-size: 1.4em;
      color: #fff;
      line-height: 1.4em;
      font-weight: 200;
      text-align: end;
      padding: 0.9em;
      margin: 0.5em; }
    .old .mainbar.borderless .display-3 {
      font-size: 1.3em;
      color: #fff;
      line-height: 1.4em;
      font-weight: 100;
      text-align: left;
      padding: 0.9em;
      margin: 0.5em; }
      .old .mainbar.borderless .display-3.list {
        padding: 0 !important;
        margin-left: 0.3em; }
    .old .mainbar.borderless .tab {
      overflow: hidden;
      border-top: 0.5px solid #ccc;
      border-bottom: 0.5px solid #ccc;
      background-color: rgba(0, 0, 0, 0.7); }
    .old .mainbar.borderless .tab button {
      background-color: rgba(0, 0, 0, 0.2);
      float: left;
      border: 0.5px solid #ccc;
      outline: none;
      cursor: pointer;
      padding: 14px 16px;
      -webkit-transition: 0.3s;
      transition: 0.3s;
      font-size: 17px; }
    .old .mainbar.borderless .tab button:hover {
      background-color: #124e8b; }
    .old .mainbar.borderless .tab button.active {
      background-color: #174760; }
    .old .mainbar.borderless h4 {
      margin: 0;
      padding: 0;
      border: 0;
      outline: 0;
      background: 0 0;
      font-size: 100%;
      vertical-align: baseline; }
    .old .mainbar.borderless .tabcontent {
      display: none; }
  .old #newMessage {
    border: none; }
  .old #mainPage-message-wrapper {
    margin: 0;
    padding: 0; }
    .old #mainPage-message-wrapper .span-btn {
      margin-left: 10px;
      position: relative;
      top: 2px; }
    .old #mainPage-message-wrapper #recipients-wrapper {
      overflow-x: hidden;
      height: 100%;
      background: #ECF0F5;
      color: black;
      position: relative;
      top: 0; }
    .old #mainPage-message-wrapper #message-write-container {
      position: relative;
      bottom: 0;
      width: 100%;
      border: 1px solid lightgray;
      border-bottom: none;
      background: #ECF0F5;
      -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(255, 255, 255, 0.6);
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.1), inset 0 0 1px rgba(255, 255, 255, 0.6);
      -webkit-transition: 0.3s all linear;
      transition: 0.3s all linear; }
      .old #mainPage-message-wrapper #message-write-container.ng-hide {
        bottom: -75px; }
      .old #mainPage-message-wrapper #message-write-container .input-group {
        width: 75%;
        margin: 0 auto;
        padding: 15px 0; }
        .old #mainPage-message-wrapper #message-write-container .input-group input {
          height: 34px;
          outline: none; }
      .old #mainPage-message-wrapper #message-write-container #message-icons {
        margin-bottom: 5px; }
        .old #mainPage-message-wrapper #message-write-container #message-icons button,
        .old #mainPage-message-wrapper #message-write-container #message-icons label {
          background: transparent none;
          outline: none;
          border: none;
          cursor: pointer;
          margin-left: 10px; }
      .old #mainPage-message-wrapper #message-write-container label {
        display: none;
        margin-left: 5px;
        cursor: pointer; }
      .old #mainPage-message-wrapper #message-write-container #text {
        width: 50%;
        resize: none !important;
        margin: 0 !important;
        outline: none;
        padding: 5px 50px 5px 10px;
        height: 28px;
        border: 1px solid #cccccc;
        border-radius: 15px; }
    .old #mainPage-message-wrapper .msgHeading {
      color: black;
      margin: 20px auto;
      font-size: 18px;
      font-weight: bold;
      text-align: center;
      border-bottom: 1px solid lightseagreen;
      max-width: 210px; }
    .old #mainPage-message-wrapper .msgButton {
      background: #ECF0F5;
      outline: none;
      border: none;
      padding: 5px;
      width: 75%;
      margin-bottom: 15px;
      color: black;
      -webkit-transition: 0.2s all linear;
      transition: 0.2s all linear;
      font-weight: bold;
      text-transform: uppercase;
      font-size: 12px;
      -webkit-box-shadow: 0 0 25px rgba(255, 255, 255, 0.1), inset 0 0 3px rgba(0, 0, 0, 0.6);
      box-shadow: 0 0 25px rgba(255, 255, 255, 0.1), inset 0 0 3px rgba(0, 0, 0, 0.6); }
      .old #mainPage-message-wrapper .msgButton:hover {
        background: #266AB3;
        color: white; }
      .old #mainPage-message-wrapper .msgButton.green {
        background: #33A657;
        color: white; }
        .old #mainPage-message-wrapper .msgButton.green.ng-leave, .old #mainPage-message-wrapper .msgButton.green.ng-enter, .old #mainPage-message-wrapper .msgButton.green.ng-leave-active, .old #mainPage-message-wrapper .msgButton.green.ng-enter-active {
          opacity: 0; }
        .old #mainPage-message-wrapper .msgButton.green:hover {
          background: #46c66e; }
      .old #mainPage-message-wrapper .msgButton.red {
        background: #CA341B;
        color: white; }
        .old #mainPage-message-wrapper .msgButton.red:hover {
          background: #e44d34; }
  .old #closeBtn {
    display: none; }
  .old #message-list {
    height: inherit;
    background: #ECF0F5;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    -webkit-transition: 0.3s all linear;
    transition: 0.3s all linear;
    border-left: 1px solid black; }
    .old #message-list .msgHeading {
      color: black; }
    .old #message-list a img {
      cursor: pointer; }
    .old #message-list .message-list-item {
      overflow: hidden;
      padding: 0.5em 0.5em;
      border: 1px solid rgba(255, 255, 255, 0.15);
      -webkit-transition: 0.3s all linear;
      transition: 0.3s all linear;
      cursor: default;
      height: 60px;
      color: black;
      text-align: left;
      margin: 5px;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      background: rgba(255, 255, 255, 0.5); }
      .old #message-list .message-list-item p:first-of-type {
        font-weight: bold;
        margin-bottom: 10px;
        font-size: 15px;
        width: auto;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 175px; }
      .old #message-list .message-list-item.selected {
        background: #558FC0;
        -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        color: white; }
      .old #message-list .message-list-item:hover {
        background-color: #b8c7ce; }
      .old #message-list .message-list-item #message {
        font-weight: normal;
        max-width: 160px; }
      .old #message-list .message-list-item .message-list-item-date {
        font-weight: bold;
        font-style: italic;
        font-size: 13px;
        display: inline-block; }
      .old #message-list .message-list-item .message-list-item-main {
        width: 100%;
        float: left; }
        .old #message-list .message-list-item .message-list-item-main #avatar {
          float: left;
          margin-right: 5px; }
          .old #message-list .message-list-item .message-list-item-main #avatar img {
            border-radius: 50%;
            width: 30px;
            height: 30px; }
          .old #message-list .message-list-item .message-list-item-main #avatar #newBadge {
            color: #CA341B;
            font-weight: bold;
            text-align: center;
            margin-bottom: 1px;
            font-size: 13px; }
        .old #message-list .message-list-item .message-list-item-main .message-list-item-header {
          font-weight: bold;
          overflow: hidden;
          max-height: 100px; }
        .old #message-list .message-list-item .message-list-item-main:not(.message-list-item-header) {
          font-weight: normal; }
          .old #message-list .message-list-item .message-list-item-main:not(.message-list-item-header) .message-list-item-date {
            float: right; }
        .old #message-list .message-list-item .message-list-item-main .message-list-item-short-message {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px; }
      .old #message-list .message-list-item .message-list-item-buttons {
        float: left;
        width: 70px;
        margin-left: 1em;
        margin-top: 0.75em; }
        .old #message-list .message-list-item .message-list-item-buttons a {
          font-size: 20px;
          text-align: center;
          width: 35px;
          float: left;
          padding: 0;
          margin: 0; }
  .old #recipient-container {
    height: inherit;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: left;
    position: relative;
    left: 0;
    -webkit-transition: 0.5s all linear;
    transition: 0.5s all linear;
    cursor: default; }
    .old #recipient-container a img {
      cursor: pointer; }
    .old #recipient-container .recipient-images {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      position: relative;
      -webkit-transition: 0.3s all linear;
      transition: 0.3s all linear;
      left: -35px; }
      .old #recipient-container .recipient-images:hover {
        left: 0; }
      .old #recipient-container .recipient-images img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin: 2px; }
  .old #addUser {
    padding: 0 5px 5px 5px;
    position: absolute;
    top: 0;
    left: 100%;
    -webkit-transition: 0.5s all linear;
    transition: 0.5s all linear;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    height: inherit;
    text-align: left; }
    .old #addUser .cimjegyzek {
      display: block;
      font-weight: bold;
      cursor: pointer;
      -webkit-transition: 0.2s all linear;
      transition: 0.2s all linear;
      text-decoration: underline;
      margin-bottom: 10px;
      padding: 5px;
      border-radius: 5px; }
      .old #addUser .cimjegyzek:hover {
        background-color: #D2D6DE; }
    .old #addUser .col-md-6 {
      padding: 1px; }
    .old #addUser .msgButton {
      width: 100%; }
    .old #addUser ul {
      clear: both;
      list-style-type: none;
      padding: 0;
      display: none; }
      .old #addUser ul li {
        cursor: pointer;
        -webkit-transition: 0.2s all linear;
        transition: 0.2s all linear;
        text-overflow: ellipsis;
        border-radius: 5px;
        padding: 5px 5px 5px 15px;
        margin: 1px; }
        .old #addUser ul li:first-child {
          margin-top: 2.5px; }
        .old #addUser ul li:hover {
          background-color: #D2D6DE; }
        .old #addUser ul li.green {
          background-color: #33A657;
          color: #FFFFFF;
          border-radius: 5px 5px 30px 5px; }
        .old #addUser ul li img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          margin: 2px; }
  .old #msgImg {
    width: 100%;
    position: absolute;
    bottom: 65px;
    left: 0;
    overflow-x: auto;
    -webkit-transition: 0.2s all linear;
    transition: 0.2s all linear;
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap; }
    .old #msgImg .msgImgDisplay {
      -webkit-transition: 0.3s all linear;
      transition: 0.3s all linear;
      overflow: hidden; }
      .old #msgImg .msgImgDisplay.ng-enter, .old #msgImg .msgImgDisplay.ng-enter-active {
        -webkit-animation: img-appear 0.5s linear;
        animation: img-appear 0.5s linear; }
      .old #msgImg .msgImgDisplay.ng-leave, .old #msgImg .msgImgDisplay.ng-leave-active {
        -webkit-animation: img-disappear 0.5s linear;
        animation: img-disappear 0.5s linear; }
    .old #msgImg img {
      width: 50px;
      height: 50px;
      -o-object-fit: cover;
      object-fit: cover;
      margin: 5px;
      -webkit-transition: 0.3s all linear;
      transition: 0.3s all linear; }
    .old #msgImg .delCross {
      width: 50px;
      height: 50px;
      position: absolute;
      opacity: 0;
      -webkit-transition: none;
      transition: none; }
      .old #msgImg .delCross:hover {
        opacity: 1; }
  .old #message-body {
    height: inherit;
    padding: 0;
    border-left: 0.5px solid #cccccc;
    border-right: 0.5px solid #cccccc;
    background-color: #ECF0F5; }
  .old #message-options {
    border: none;
    padding: 0;
    background: transparent;
    position: relative;
    height: 20px;
    z-index: 9; }
    .old #message-options button {
      width: 49.5%;
      background: white;
      outline: none;
      padding: 0;
      top: 0;
      position: relative;
      height: 20px;
      border: 1px solid lightgray;
      -webkit-transition: all 0.2s linear;
      transition: all 0.2s linear;
      text-transform: uppercase;
      font-size: 12px; }
      .old #message-options button.ng-hide {
        top: -20px; }
      .old #message-options button.delBtn:hover {
        background: #CA341B;
        color: white;
        border: none; }
      .old #message-options button.unSubBtn:hover {
        background: #3174A8;
        color: white;
        border: none; }
      .old #message-options button.recipientsBtn {
        display: none; }
        .old #message-options button.recipientsBtn.ng-hide {
          top: -20px; }
  .old #message-detail {
    position: relative;
    max-height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 5px;
    -webkit-transition: 0.2s all linear;
    transition: 0.2s all linear; }
    .old #message-detail #messages-body {
      position: absolute;
      left: 0;
      right: 0;
      padding-left: 10px; }
      .old #message-detail #messages-body.ng-enter, .old #message-detail #messages-body.ng-enter-active {
        -webkit-animation: fadeIn 0.75s ease-in;
        animation: fadeIn 0.75s ease-in; }
      .old #message-detail #messages-body.ng-leave, .old #message-detail #messages-body.ng-leave-active {
        -webkit-animation: fadeOut 0.75s ease-out;
        animation: fadeOut 0.75s ease-out; }
    .old #message-detail #startPage {
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      -webkit-transition: 0.75s all ease-in-out;
      transition: 0.75s all ease-in-out; }
      .old #message-detail #startPage.ng-leave {
        opacity: 1; }
      .old #message-detail #startPage.ng-leave-active {
        opacity: 0; }
      .old #message-detail #startPage.ng-enter {
        opacity: 0; }
      .old #message-detail #startPage.ng-enter-active {
        opacity: 1; }
    .old #message-detail #letter-parent {
      -webkit-animation: bounce 4s infinite ease-in-out;
      animation: bounce 4s infinite ease-in-out; }
      .old #message-detail #letter-parent #letter-guy {
        max-width: 233px;
        max-height: 233px;
        width: 233px;
        height: 233px;
        margin: 0 auto;
        background-image: url("../images/Uzenetek/letter_guy_flying.gif"), url("../images/Uzenetek/letter_guy_ohno.gif"); }
        .old #message-detail #letter-parent #letter-guy:hover {
          -webkit-animation: shake-top 4s ease-in-out infinite both;
          animation: shake-top 4s ease-in-out infinite both;
          background-image: url("../images/Uzenetek/letter_guy_ohno.gif"); }
  .old #searchInput {
    width: 100%;
    padding: 5px;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.06);
    margin-bottom: 10px;
    background-color: rgba(0, 0, 0, 0.04);
    border-radius: 5px; }
  .old #searchResult ul li.ng-enter {
    opacity: 0; }
  .old #searchResult ul li.ng-enter-active {
    opacity: 1; }
  .old #searchResult ul li.ng-leave {
    opacity: 1; }
  .old #searchResult ul li.ng-leave-active {
    opacity: 0; }
  .old .messenger-message-item pre,
  .old .messenger-message-item p.message {
    white-space: pre-wrap;
    float: left;
    margin: 0;
    padding: 10px;
    min-height: 35px; }
  .old .messenger-message-item img {
    width: 30px;
    height: 30px;
    float: left;
    margin: 0;
    padding: 0;
    border-radius: 50%;
    position: relative;
    bottom: 7px; }
  .old .messenger-message-item p.nev {
    margin: 5px 0 5px 35px;
    font-size: 12px; }
  .old .messenger-message-item .message-wrapper {
    float: left;
    width: calc(100% - 140px);
    margin: 0 85px 0 5px; }
    .old .messenger-message-item .message-wrapper .message-images {
      float: left;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      max-width: 300px;
      -webkit-box-pack: start;
      -ms-flex-pack: start;
      justify-content: flex-start;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      clear: both; }
      .old .messenger-message-item .message-wrapper .message-images .message-image {
        margin-right: -25px; }
        .old .messenger-message-item .message-wrapper .message-images .message-image img {
          position: relative;
          top: 1px;
          border-radius: 10px;
          padding: 2px;
          width: 60px;
          height: 60px;
          -o-object-fit: cover;
          object-fit: cover;
          -webkit-transition: .75s all linear;
          transition: .75s all linear; }
        .old .messenger-message-item .message-wrapper .message-images .message-image #deleteImage {
          width: 25px;
          height: 25px;
          position: relative;
          top: -2px;
          right: 25px;
          cursor: pointer; }
  
  .old .messenger-message-item p.message {
    padding: 0.5em 1em;
    background-color: #d2d6de;
    color: #000000;
    border-radius: 0 15px 15px 15px;
    width: auto;
    min-height: 35px;
    font-size: 16px; }
  .old .messenger-message-item p.datum {
    font-size: 12px;
    font-style: italic; }
  .old .messenger-message-item p:not(.message) {
    clear: left; }
  .old .messenger-message-item.sender img.felado,
  .old .messenger-message-item.sender p.nev {
    visibility: hidden; }
  .old .messenger-message-item.sender p.datum {
    text-align: right;
    clear: right;
    float: right;
    font-size: 12px;
    font-style: italic; }
  .old .messenger-message-item.sender .message-wrapper {
    margin: 0 5px 0 85px; }
    .old .messenger-message-item.sender .message-wrapper .message-images {
      float: right;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end; }
    .old .messenger-message-item.sender .message-wrapper .seenBox {
      clear: both; }
      .old .messenger-message-item.sender .message-wrapper .seenBox span .seenByImg {
        width: 15px;
        height: 15px;
        float: right; }
  .old .messenger-message-item.sender p.message {
    background-color: #4A8EBE;
    color: #FFFFFF;
    text-align: right;
    float: right;
    border-radius: 15px 15px 0 15px;
    min-height: 35px;
    font-size: 16px; }
  .old .messenger-message-item:not(.sender) img.kuldo {
    visibility: hidden; }
  .old .messenger-message-item:not(.sender) .seenBox {
    clear: both; }
    .old .messenger-message-item:not(.sender) .seenBox span .seenByImg {
      width: 15px;
      height: 15px;
      float: left; }
  .old body {
    padding-top: 3.6rem;
    min-width: 350px;
    position: static !important; }
  .old #mainContent,
  .old .crossfade {
    position: relative;
    width: 100%;
    left: 0;
    right: 0; }
    .old #mainContent.ng-enter, .old #mainContent.ng-enter-active,
    .old .crossfade.ng-enter,
    .old .crossfade.ng-enter-active {
      -webkit-animation: fadeIn 0.75s ease-in;
      animation: fadeIn 0.75s ease-in; }
    .old #mainContent.ng-leave, .old #mainContent.ng-leave-active,
    .old .crossfade.ng-leave,
    .old .crossfade.ng-leave-active {
      -webkit-animation: fadeOut 0.75 ease-out;
      animation: fadeOut 0.75 ease-out; }
  .old ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5; }
  .old ::-webkit-scrollbar {
    width: 7px;
    background-color: #F5F5F5; }
  .old ::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #508DBB; }
  .old [data-content] {
    content: ''; }
  .old a {
    color: #266AB3;
    cursor: pointer; }
    .old a:hover {
      color: #3174A8;
      text-decoration: none; }
    .old a:hover, .old a:focus, .old a:active {
      outline: 0;
      text-decoration: none; }
  .old .small-logo {
    height: 55px !important; }
  .old section {
    overflow: hidden; }
  .old pre {
    white-space: pre-wrap; }
  .old h1,
  .old h2 {
    margin: 0.5em 0.25em;
    padding: 0; }
  .old h1 {
    font-size: 3rem;
    line-height: 1.2em; }
  
  .old h2 {
    font-size: 1.25rem;
    line-height: 1.2em; }
  .old #dateSpan {
    color: #CA341B; }
  .old .disabled label {
    color: #c8c8c8; }
  .old .css-right-scrollbug .e-scrollcss {
    padding-right: 0; }
  .old .alert {
    clear: both; }
  .old .fas, .old .far {
    font-weight: 900;
    float: left;
    line-height: 40px;
    font-size: 19px;
    margin: 0 10px;
    color: white;
    width: 25px;
    text-align: center; }
  .old .btn {
    border-radius: 0 0 5px 0; }
  .old .btn.btn-primary {
    background: #266AB3; }
  .old .btn.btn-primary:hover {
    background: #3174A8; }
  .old .cts-button {
    background-color: #3174A8 !important;
    color: white !important;
    padding: 0.5em 1em !important;
    border-radius: 6px !important;
    -webkit-transition: background-color 0.2s linear, color 0.2s linear;
    transition: background-color 0.2s linear, color 0.2s linear;
    min-width: 100px !important;
    margin: 2px;
    -webkit-box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1) inset;
    box-shadow: 0 -1px 0 rgba(255, 255, 255, 0.1) inset;
    border-style: solid;
    border-width: 0px 0px 3px;
    text-shadow: 0px 1px 0px black;
    position: relative; }
    .old .cts-button:active {
      border: none; }
    .old .cts-button:hover {
      background-color: #3783d5 !important; }
    .old .cts-button.auto-width {
      width: auto !important; }
    .old .cts-button.e-disable {
      background-color: #676767 !important; }
    .old .cts-button.e-disable:hover {
      background-color: #818181 !important;
      cursor: not-allowed; }
    .old .cts-button:disabled {
      background-color: #676767 !important;
      cursor: not-allowed; }
    .old .cts-button.next {
      text-align: left; }
      .old .cts-button.next::after {
        content: "";
        width: 0;
        height: 0;
        display: block;
        border-top: 18px solid white;
        border-bottom: 20px solid white;
        border-left: 20px solid transparent;
        border-right: 10px solid white;
        position: absolute;
        right: 0;
        top: 0;
        border-radius: 0 5px 5px 0; }
    .old .cts-button.back {
      text-align: right; }
      .old .cts-button.back::after {
        content: "";
        width: 0;
        height: 0;
        display: block;
        border-top: 18px solid white;
        border-bottom: 20px solid white;
        border-right: 20px solid transparent;
        border-left: 10px solid white;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 5px 0 0 5px; }
    .old .cts-button.green {
      background-color: #1BB683 !important; }
      .old .cts-button.green:hover {
        background-color: #25dfa2 !important; }
    .old .cts-button.orange {
      background-color: #FFA826 !important; }
      .old .cts-button.orange:hover {
        background-color: #ffbc59 !important; }
    .old .cts-button.purple {
      background-color: #3C32BD !important; }
      .old .cts-button.purple:hover {
        background-color: #5a51d1 !important; }
    .old .cts-button.yellow {
      background-color: #FFCD26 !important; }
      .old .cts-button.yellow:hover {
        background-color: #ffd959 !important; }
    .old .cts-button.red {
      background-color: #CA341B !important; }
      .old .cts-button.red:hover {
        background-color: #e44d34 !important; }
  .old .cts-button.widthfull {
    width: 100% !important; }
  .old .radioBtn {
    width: 3em;
    float: left; }
    .old .radioBtn .labelBtn {
      margin-left: 0.2em; }
  .old .radioBtn2 {
    padding-top: 1.5em;
    padding-left: 1em;
    padding-right: 1.5em;
    width: 7em;
    float: left; }
    .old .radioBtn2 .labelBtn {
      margin-left: 0.3em; }
  .old .hide-content {
    visibility: hidden; }
  .old .overflow-hidden {
    overflow: hidden; }
  .old a.helpText {
    color: #FFFFFF; }
    .old a.helpText:hover {
      color: #ffffff; }
  .old ::-webkit-input-placeholder {
    color: #c8c8c8; }
  .old ::-moz-placeholder {
    color: #c8c8c8; }
  .old :-ms-input-placeholder {
    color: #c8c8c8; }
  .old input:-moz-placeholder {
    color: #c8c8c8; }
  .old .hidedContent {
    margin-top: 3em;
    overflow: hidden;
    width: 100%;
    height: 0;
    /* For Safari 3.1 to 6.0 */
    -webkit-transition-property: height;
    /* Standard syntax */
    transition-property: height; }
  .old .e-dialog.e-js.e-widget-content.e-box,
  .old .trv-page-wrapper.active {
    height: 100% !important; }
  .old .trv-page-wrapper.active .trv-report-page {
    width: auto !important;
    height: auto !important; }
  .old .trv-page-wrapper.active .trv-report-page .sheet {
    -webkit-transform: scale(1, 1) !important;
    transform: scale(1, 1) !important; }
  .old .form-control[disabled],
  .old input[disabled] {
    cursor: inherit;
    color: black;
    opacity: 1;
    background-color: transparent; }
  .old span.e-disable-wrap span, .old span.e-disable-wrap .e-button:hover span, .old .e-button:hover span.e-disable-wrap span {
    border-color: #e5e5e5; }
  .old .paint button {
    border: 2px solid #FFFFFF; }
    .old .paint button:hover {
      border: 2px solid #1E74A6 !important; }
    .old .paint button:disabled {
      border: 2px solid #FFFFFF;
      opacity: 0.25; }
    .old .paint button.active {
      border: 2px solid #1E74A6;
      opacity: 1; }
    .old .paint button:focus {
      outline: none;
      border: 2px solid #FFFFFF; }
  .old .paint button.white-border {
    border-color: #FFF !important; }
  .old form .form-control,
  .old form .e-input {
    font-size: 1rem;
    line-height: 1.4em;
    -webkit-box-shadow: none;
    box-shadow: none; }
  .old form .input-group-addon {
    padding: 6px; }
  .old form .form-control:not(.textarea) {
    height: 30px;
    padding: 0 12px; }
  .old form input,
  .old form textarea {
    border-radius: 0; }
  .old form .button-wrapper {
    padding: 5px;
    overflow: hidden;
    text-align: left;
    clear: right;
    margin-bottom: 5px; }
    .old form .button-wrapper button {
      margin-left: 1.25px;
      margin-right: 1.25px;
      min-width: 115px !important; }
  .old form .image-preview {
    margin: 0 auto;
    overflow: hidden;
    max-width: 23rem;
    width: 200px;
    padding-bottom: 2em; }
  .old form .cts-form-group {
    overflow: hidden;
    margin-bottom: 15px; }
    .old form .cts-form-group label {
      text-align: right;
      font-weight: normal; }
    .old form .cts-form-group.chkbox label {
      text-align: right;
      font-weight: normal;
      float: left;
      width: 50vw; }
    .old form .cts-form-group.chkbox > div {
      float: left;
      padding-left: 1em; }
      .old form .cts-form-group.chkbox > div .e-chkbox-wrap {
        margin-top: 0.25em; }
    .old form .cts-form-group .validation-messages {
      margin-top: 0.25em;
      display: none; }
      .old form .cts-form-group .validation-messages input.error {
        color: #CA341B; }
    .old form .cts-form-group.required label {
      font-weight: bold; }
      .old form .cts-form-group.required label::after {
        content: '*'; }
    .old form .cts-form-group.error label {
      color: #CA341B; }
    .old form .cts-form-group.error .validation-messages {
      display: inherit; }
  .old form:not(.filter):not(.popup) .input-group {
    width: 100% !important; }
  .old form:not(.filter):not(.popup) .input-group .input-group-addon {
    border-left-width: 0 !important;
    cursor: pointer; }
  .old form:not(.filter):not(.popup) .button-wrapper {
    padding: 1em; }
  .old form.filter {
    overflow: hidden; }
    .old form.filter .cts-form-group {
      width: 100%; }
      .old form.filter .cts-form-group label {
        width: 100%;
        text-align: left; }
      .old form.filter .cts-form-group > div {
        width: 100%; }
      .old form.filter .cts-form-group.chkbox label {
        position: relative;
        top: 29px;
        left: 23px; }
  .old form.popup .cts-form-group {
    width: 100%; }
    .old form.popup .cts-form-group label {
      width: 100%;
      text-align: left; }
    .old form.popup .cts-form-group > div {
      width: 100%; }
    .old form.popup .cts-form-group.chkbox label {
      text-align: center; }
    .old form.popup .cts-form-group.chkbox > div {
      text-align: center; }
  .old form .e-menu-wrap {
    margin-bottom: 1em; }
    .old form .e-menu-wrap li {
      background-color: #266AB3 !important;
      color: #FFFFFF !important;
      border: 0; }
      .old form .e-menu-wrap li a {
        color: #FFFFFF !important; }
      .old form .e-menu-wrap li.e-disable-item {
        opacity: 0.5; }
        .old form .e-menu-wrap li.e-disable-item * {
          opacity: 1 !important; }
    .old form .e-menu-wrap #menuEvents {
      border: none; }
  .old .dropdown-menu {
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    -webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
    animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both; }
  .old .panel-danger {
    border: none;
    -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
    box-shadow: 0 5px 5px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23); }
    .old .panel-danger .panel-heading {
      color: #FFFFFF;
      background: -webkit-gradient(linear, left top, right top, from(#CA341B), to(#b31217)) top right;
      background: linear-gradient(to right, #CA341B, #b31217) top right;
      border: none;
      padding: 7px 15px;
      text-shadow: 0px 1px 0px black; }
    .old .panel-danger .panel-body {
      border: 1px solid #e5e5e5; }
  .old .mainbar {
    margin: 0 0 0 230px;
    padding: 2rem 1.5rem;
    background: #ECF0F5; }
    .old .mainbar.borderless {
      margin: 0;
      padding: 0; }
    .old .mainbar .row .widget {
      background: #ffffff;
      -webkit-box-shadow: 0 5px 5px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23);
      box-shadow: 0 5px 5px rgba(0, 0, 0, 0.19), 0 3px 3px rgba(0, 0, 0, 0.23); }
      .old .mainbar .row .widget .widget-head {
        height: 52px;
        padding: 0.25em 1em;
        margin: 0;
        color: #FFFFFF;
        background: #266AB3;
        background: -webkit-gradient(linear, left top, right top, from(#266ab3), to(#2a5298));
        background: linear-gradient(to right, #266ab3, #2a5298);
        border-bottom: 1px solid black;
        text-shadow: 0px 1px 0px black; }
        .old .mainbar .row .widget .widget-head h2 {
          line-height: 26px;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          white-space: nowrap !important; }
      .old .mainbar .row .widget .widgetlabel {
        padding: 10px 0 5px 10px; }
      .old .mainbar .row .widget .widget-head-disabled {
        height: 52px;
        padding: 0.25em 1em;
        margin: 0;
        color: #FFFFFF;
        background: #626c7b;
        background: -webkit-gradient(linear, left top, right top, from(#6f7a8b), to(#4c545f));
        background: linear-gradient(to right, #6f7a8b, #4c545f);
        border-bottom: 1px solid black;
        border-radius: 5px 0 0 0; }
        .old .mainbar .row .widget .widget-head-disabled h2 {
          line-height: 26px;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
          white-space: nowrap !important; }
      .old .mainbar .row .widget .widget-content {
        overflow: hidden;
        padding: 1em;
        margin: 0;
        border: 0.1em solid #e5e5e5;
        border-top-width: 0;
        /***********************************************
                    * Syncfusion és Bootstrap elemek
                    ***********************************************/
        /***********************************************
                    * Psychology
                    ***********************************************/
        /***********************************************
                    * Vissza gomb
                    ***********************************************/ }
        .old .mainbar .row .widget .widget-content.form {
          padding-top: 2em; }
        .old .mainbar .row .widget .widget-content form .thumb {
          width: 100%;
          max-width: 200px;
          border: 0.1em solid #676767; }
        .old .mainbar .row .widget .widget-content #Schedule1 div.image {
          text-align: center;
          width: 100%; }
        .old .mainbar .row .widget .widget-content #Schedule1 img {
          width: 30px;
          height: 30px;
          opacity: 0.5; }
        .old .mainbar .row .widget .widget-content #Schedule1 .caption {
          display: block;
          opacity: 0.5;
          overflow: hidden; }
        .old .mainbar .row .widget .widget-content .inline-table {
          table-layout: auto;
          border-collapse: collapse;
          border-spacing: 0;
          padding: 0;
          margin: 0; }
          .old .mainbar .row .widget .widget-content .inline-table tr {
            padding: 0;
            margin: 0; }
            .old .mainbar .row .widget .widget-content .inline-table tr td {
              padding: 0;
              margin: 0;
              border: 0; }
        .old .mainbar .row .widget .widget-content .alert {
          margin-top: 2rem; }
        .old .mainbar .row .widget .widget-content div.attendance-report-div {
          overflow: hidden;
          margin-bottom: 0.25rem; }
          .old .mainbar .row .widget .widget-content div.attendance-report-div p {
            float: left;
            padding-right: 2rem; }
        .old .mainbar .row .widget .widget-content table.attendance-report-table {
          width: 100%;
          margin: 0 0 2rem; }
          .old .mainbar .row .widget .widget-content table.attendance-report-table td,
          .old .mainbar .row .widget .widget-content table.attendance-report-table th {
            padding: 0.5rem 0.5rem; }
          .old .mainbar .row .widget .widget-content table.attendance-report-table td:first-child {
            font-weight: bold; }
        .old .mainbar .row .widget .widget-content .e-row.e-hover {
          color: #FFFFFF !important; }
          .old .mainbar .row .widget .widget-content .e-row.e-hover td a {
            color: #ffffff; }
        .old .mainbar .row .widget .widget-content .e-alt_row.e-hover {
          color: #FFFFFF !important; }
          .old .mainbar .row .widget .widget-content .e-alt_row.e-hover td a {
            color: #ffffff; }
        .old .mainbar .row .widget .widget-content .cts-block {
          margin-top: 1em;
          border-bottom: 0.1em solid #676767; }
          .old .mainbar .row .widget .widget-content .cts-block:last-child {
            border: none; }
        .old .mainbar .row .widget .widget-content .psycho-test {
          margin: 0 auto; }
          .old .mainbar .row .widget .widget-content .psycho-test .test-header, .old .mainbar .row .widget .widget-content .psycho-test .test-evaluation {
            margin-bottom: 1em; }
          .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body {
            width: 100%;
            margin: 0 auto; }
            .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body .description {
              height: auto; }
            .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table {
              border-collapse: collapse !important;
              margin-bottom: 1em; }
              .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table.pieron-table tr:nth-child(5n) td {
                padding-bottom: 1.5rem; }
              .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table.pieron-table tr:last-child td {
                padding-bottom: 0; }
              .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr {
                background-color: #FFFFFF; }
                .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper td {
                  border: 0.025em solid #cccccc; }
                  .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper td.question-index {
                    width: 2em;
                    text-align: center;
                    height: 100%; }
                  .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper td.question {
                    width: calc(100% - 12em);
                    padding: 0.2em 1em; }
                  .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper td.answer {
                    text-align: center;
                    width: 2em;
                    cursor: pointer; }
                    .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper td.answer span {
                      display: block;
                      width: 1.6em;
                      height: 1.6em;
                      padding: 0 0 0.1em 0;
                      margin: 0 auto;
                      border: 0.15em solid transparent;
                      border-radius: 100%; }
                    .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper td.answer.selected span {
                      border-color: #3174A8;
                      border-radius: 100%; }
                    .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper td.answer.selected-red span {
                      border-color: #CA341B;
                      border-radius: 100%; }
                    .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper td.answer.selected-green span {
                      border-color: #33A657;
                      border-radius: 100%; }
                    .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper td.answer.selected-minutes span,
                    .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper td.answer.selected-minutes-good span,
                    .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper td.answer.selected-minutes-bad span {
                      background-color: #cccccc; }
                .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body table tr.question-wrapper:nth-of-type(2n) {
                  background-color: #ffffff; }
          .old .mainbar .row .widget .widget-content .psycho-test.pieron .psycho-test-body table tr {
            background-color: #ffffff; }
            .old .mainbar .row .widget .widget-content .psycho-test.pieron .psycho-test-body table tr.question-wrapper td {
              border-color: transparent; }
              .old .mainbar .row .widget .widget-content .psycho-test.pieron .psycho-test-body table tr.question-wrapper td.answer span {
                width: auto;
                height: auto;
                padding: 0.25em; }
                .old .mainbar .row .widget .widget-content .psycho-test.pieron .psycho-test-body table tr.question-wrapper td.answer span.not-filled {
                  background-color: #cccccc; }
        .old .mainbar .row .widget .widget-content .e-acrdn h3 {
          clear: both;
          background-color: #266AB3; }
          .old .mainbar .row .widget .widget-content .e-acrdn h3:hover {
            background-color: #3174A8; }
            .old .mainbar .row .widget .widget-content .e-acrdn h3:hover span {
              color: #FFFFFF; }
          .old .mainbar .row .widget .widget-content .e-acrdn h3 a,
          .old .mainbar .row .widget .widget-content .e-acrdn h3 a:hover,
          .old .mainbar .row .widget .widget-content .e-acrdn h3 span {
            color: #FFFFFF; }
        .old .mainbar .row .widget .widget-content .e-acrdn fieldset {
          border: 0; }
        .old .mainbar .row .widget .widget-content .back {
          padding-top: 1.25em; }
          .old .mainbar .row .widget .widget-content .back #sportagSpecifikus {
            height: 50px; }
            .old .mainbar .row .widget .widget-content .back #sportagSpecifikus span {
              float: left; }
      .old .mainbar .row .widget .widget-foot {
        background-color: #e5e5e5;
        border-top: 0;
        padding: 0.5em 1em; }
  .old .profile-background::before {
    content: "";
    top: 0;
    left: -50px;
    min-width: 120vw;
    min-height: 115vh;
    position: fixed;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(255, 255, 255, 0.1);
    overflow: hidden; }
  .old .profile-background {
    top: 0;
    left: -50px;
    min-width: 120vw;
    min-height: 115vh;
    position: fixed;
    background: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.5))), url("../images/loginBackground4.jpg") no-repeat bottom;
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url("../images/loginBackground4.jpg") no-repeat bottom;
    background-size: cover;
    overflow: hidden; }
    .old .profile-background .login-center {
      color: rgba(0, 0, 0, 0.6);
      -webkit-animation: loginShow 1s ease-out 0.3s forwards;
      animation: loginShow 1s ease-in-out 0.3s forwards;
      position: fixed;
      z-index: 10001;
      text-align: left;
      top: 60%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      width: 50vw;
      max-width: 28rem;
      min-width: 20rem;
      opacity: 0;
      background: rgba(255, 255, 255, 0.07);
      border: 2px solid rgba(255, 255, 255, 0.4);
      border-radius: 5px;
      padding: 3.2vh 1.6vw;
      overflow: hidden; }
      .old .profile-background .login-center button {
        font-family: "Calibri", Arial;
        font-size: 16px;
        color: #fff;
        line-height: 1.2;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 0 20px;
        min-width: 150px;
        height: 41px;
        background-color: #333333;
        border-color: rgba(255, 255, 255, 0.6);
        border-radius: 10px;
        -webkit-transition: all 0.4s;
        transition: all 0.4s;
        text-transform: uppercase;
        outline: none; }
      .old .profile-background .login-center input {
        font-family: "Calibri", Arial;
        line-height: 1.2;
        font-size: 18px;
        display: block;
        width: 100%;
        background: rgba(0, 0, 0, 0.3);
        height: 30px;
        padding: 0 10px 0 15px;
        color: rgba(255, 255, 255, 0.8);
        border: 0.5px solid rgba(255, 255, 255, 0.4); }
        .old .profile-background .login-center input.baseInputStyle {
          height: 45px; }
          .old .profile-background .login-center input.baseInputStyle.error {
            border-color: #CA341B; }
      .old .profile-background .login-center input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.8) inset;
        box-shadow: 0 0 0 1000px rgba(255, 255, 255, 0.8) inset; }
      .old .profile-background .login-center input:hover {
        border-color: #3174A8; }
      .old .profile-background .login-center input:focus {
        border-color: #3174A8; }
      .old .profile-background .login-center input::-moz-selection {
        border-color: #3174A8; }
      .old .profile-background .login-center input::selection {
        border-color: #3174A8; }
      .old .profile-background .login-center .e-ddl-popup .e-box .e-widget .e-popup {
        background: red !important; }
      .old .profile-background .login-center span.loginTitle {
        text-transform: uppercase;
        text-align: center;
        padding-bottom: 11px;
        width: 100%;
        font-size: 30px;
        line-height: 1.2;
        display: block;
        font-weight: lighter;
        color: #fff; }
      .old .profile-background .login-center .card-container.card {
        width: 100%;
        background: transparent;
        position: relative;
        margin: 0;
        border-radius: 10px;
        padding: 0; }
        .old .profile-background .login-center .card-container.card .form-group {
          margin: 1em 0; }
        .old .profile-background .login-center .card-container.card .ribbon {
          position: absolute;
          right: -0.3em;
          top: -0.3em;
          z-index: 1;
          overflow: hidden;
          width: 10rem;
          height: 6rem;
          text-align: right; }
          .old .profile-background .login-center .card-container.card .ribbon span {
            color: #FFFFFF;
            text-transform: uppercase;
            text-align: center;
            line-height: 25px;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            width: 120px;
            display: block;
            background: #266AB3;
            background: -webkit-gradient(linear, left top, left bottom, from(#266AB3), to(#0A4979));
            background: linear-gradient(#266AB3 0%, #0A4979 100%);
            -webkit-box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 0.15);
            box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 0.15);
            position: absolute;
            top: 22px;
            right: -26px; }
            .old .profile-background .login-center .card-container.card .ribbon span::before {
              content: '';
              position: absolute;
              left: 0;
              top: 100%;
              z-index: -1;
              border-left: 3px solid #266AB3;
              border-right: 3px solid transparent;
              border-bottom: 3px solid transparent;
              border-top: 3px solid #266AB3; }
            .old .profile-background .login-center .card-container.card .ribbon span::after {
              content: '';
              position: absolute;
              right: 0;
              top: 100%;
              z-index: -1;
              border-right: 3px solid #266AB3;
              border-left: 3px solid transparent;
              border-bottom: 3px solid transparent;
              border-top: 3px solid #266AB3; }
        .old .profile-background .login-center .card-container.card .profile-img-card {
          width: 6rem;
          height: 6rem;
          margin: 0 auto 0.5em;
          display: block;
          border: 0.025em solid #676767;
          border-radius: 50%; }
        .old .profile-background .login-center .card-container.card .sessionTimeout {
          display: inherit;
          padding-bottom: 0.5em;
          color: #CA341B;
          text-align: center; }
  .old .profileImg .cts-button {
    position: absolute;
    left: 125px;
    top: -90px; }
    .old .profileImg .cts-button.cancel {
      top: -50px; }
  .old .profileImg .disabled {
    opacity: 0.5;
    background-color: #eee; }
  .old .profileImg img {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    max-width: 200px;
    max-height: 200px; }
  .old .modal-body p {
    font-size: 1.2em; }
  .old .modal-footer {
    padding: 15px 0 15px 0 !important; }
  .old .dialogContainer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: table;
    visibility: hidden;
    z-index: 20000001; }
  .old .modalCell {
    display: table-cell;
    vertical-align: middle; }
  .old .cropDialog .modal-body {
    padding: 0 !important; }
  .old .row {
    margin-left: 0 !important;
    margin-right: 0 !important; }
    .old .row .cell {
      width: 50%;
      float: left; }
    .old .row .cell:first-of-type {
      padding-left: 0 !important; }
    .old .row .cell:last-of-type {
      padding-right: 0 !important; }
  .old .dialog-hide-elemet {
    display: none !important; }
  .old .table-no-margin .form-group {
    margin: 0 !important;
    padding: 0 !important; }
  .old .gridEditTemplateTextAndButton {
    float: left;
    width: 87%; }
    .old .gridEditTemplateTextAndButton > span, .old body .gridEditTemplateTextAndButton > span, .old .e-button:hover .gridEditTemplateTextAndButton > span, .old .e-button:hover body .gridEditTemplateTextAndButton > span {
      float: left;
      width: 100%; }
  .old .scroller-fix-width100 > .input-group, .old body .scroller-fix-width100 > .input-group {
    overflow: auto;
    width: 100% !important; }
  @media print {
    .old body * {
      display: none; }
    .old body .printable {
      display: block; }
    .old .e-grid tr th.e-headercell:first-child,
    .old .e-grid td.e-rowcell.e-gridchkbox,
    .old .e-grid .e-table col:first-child {
      display: none; } }
  @media (max-width: 470px) {
    .old #userNav {
      display: none; }
    .old .small-logo {
      display: none; }
    .old #userNavSM {
      display: block; }
      .old #userNavSM span {
        float: right; }
    .old .dropdown-menu {
      left: -105px; }
      .old .dropdown-menu.revertLeft {
        left: 0; }
    .old .doubleBtn {
      width: 256px !important; }
    .old .e-toolbar,
    .old .e-toolbar > .e-vertical,
    .old body .e-toolbar > .e-vertical,
    .old .e-toolbar > .e-horizontal,
    .old body .e-toolbar > .e-horizontal,
    .old .e-toolbar div:first-child {
      overflow: initial !important; } }
  @media (min-width: 480px) {
    .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body {
      width: 100%; } }
  @media (max-width: 767px) {
    .old, .old body {
      /* idegenbeli költségvetés flex */
      /* eszköznyilvántartó swal */ }
      .old .smCenter {
        text-align: center !important; }
      .old .budgetPlanContainer .budgetPlanItem {
        -webkit-box-flex: 1;
        -ms-flex-positive: 1;
        flex-grow: 1; }
      .old .assetRow {
        margin: 10px auto auto auto !important;
        text-align: center; }
        .old .assetRow label {
          text-align: center;
          margin-top: 5px;
          width: 100%; }
        .old .assetRow input {
          width: 75%; }
      .old #footer {
        display: none; }
      .old .sideMenuOpenedButton {
        display: none !important; }
      .old .sideMenuOpened {
        left: 0 !important; }
      .old .sideMenuOpenedContent .mainbar {
        margin-left: 0 !important; }
      .old .sideMenuClosedContent .mainbar {
        margin-left: 0 !important; }
      .old .sideMenuOpened .sidebar-filler {
        left: 0 !important; }
      .old .sideMenuClosedButton {
        display: none !important; }
      .old .sideMenuClosed {
        left: 0 !important; }
      .old .sideMenuClosed .sidebar-filler {
        left: 0 !important; }
      .old .sidebar-include {
        position: relative !important;
        float: none !important;
        height: auto !important; }
      .old .cts-logo .logo {
        display: none; }
      .old .sidebar {
        float: none;
        width: 100%;
        position: fixed;
        z-index: 10000;
        height: auto;
        top: 50px; }
        .old .sidebar .sidebar-dropdown {
          display: block; }
        .old .sidebar .sidebar-inner {
          height: 56vh;
          overflow-x: hidden;
          display: none; }
        .old .sidebar .sidebar-filler {
          display: none; }
      .old .mainbar {
        margin: 0;
        float: none; }
      .old .row {
        margin-top: 50px; }
      .old #athletesList_container {
        z-index: 1; }
      .old #mainPage-message-wrapper {
        margin: 32px 0 0 0;
        overflow: hidden; }
        .old #mainPage-message-wrapper #message-body #message-options {
          position: relative;
          top: -1px; }
          .old #mainPage-message-wrapper #message-body #message-options button {
            width: 30%; }
            .old #mainPage-message-wrapper #message-body #message-options button:hover {
              color: white; }
            .old #mainPage-message-wrapper #message-body #message-options button.delBtn:hover {
              background-color: #CA341B; }
            .old #mainPage-message-wrapper #message-body #message-options button.recipientsBtn {
              display: inline-block; }
              .old #mainPage-message-wrapper #message-body #message-options button.recipientsBtn.recipientsBtn:hover {
                background-color: #3174A8; }
        .old #mainPage-message-wrapper #message-body #message-write-container .input-group {
          width: 95%;
          padding: 15px 0; }
        .old #mainPage-message-wrapper #msgImg img {
          width: 50px;
          height: 50px; }
        .old #mainPage-message-wrapper #msgImg .delCross {
          width: 50px;
          height: 50px; }
        .old #mainPage-message-wrapper #message-list h3 {
          display: none; }
        .old #mainPage-message-wrapper #message-list .msgButton {
          font-size: 10px;
          margin-top: 15px;
          padding: 2px; }
        .old #mainPage-message-wrapper #message-list .message-list-item {
          height: 45px;
          margin: 4px auto;
          width: 80%;
          float: none;
          text-align: center; }
          .old #mainPage-message-wrapper #message-list .message-list-item .message-list-item-main {
            float: none; }
            .old #mainPage-message-wrapper #message-list .message-list-item .message-list-item-main #recipient,
            .old #mainPage-message-wrapper #message-list .message-list-item .message-list-item-main #message,
            .old #mainPage-message-wrapper #message-list .message-list-item .message-list-item-main .message-list-item-date {
              display: none; }
            .old #mainPage-message-wrapper #message-list .message-list-item .message-list-item-main #avatar {
              float: none;
              margin: 0; }
              .old #mainPage-message-wrapper #message-list .message-list-item .message-list-item-main #avatar #newBadge {
                float: left;
                position: absolute;
                margin-top: 7px; }
              .old #mainPage-message-wrapper #message-list .message-list-item .message-list-item-main #avatar img {
                margin: 0 0 0 -1px; }
        .old #mainPage-message-wrapper #recipients-wrapper {
          position: absolute;
          right: 0;
          height: 50%;
          width: 83%;
          border-bottom: 1px solid black; }
          .old #mainPage-message-wrapper #recipients-wrapper #recipient-container {
            height: auto; }
          .old #mainPage-message-wrapper #recipients-wrapper #addUser {
            height: auto; }
        .old #mainPage-message-wrapper #closeBtn {
          display: inline-block; }
        .old #mainPage-message-wrapper #message-body #message-icons {
          text-align: center; }
          .old #mainPage-message-wrapper #message-body #message-icons img {
            width: 24px;
            height: 24px; }
      .old .mainbar .row .widget .widget-content .button-wrapper {
        width: auto;
        text-align: center; }
      .old .auto-clear .col-xs-1:nth-child(12n+1) {
        clear: left; }
      .old .auto-clear .col-xs-2:nth-child(6n+1) {
        clear: left; }
      .old .auto-clear .col-xs-3:nth-child(4n+1) {
        clear: left; }
      .old .auto-clear .col-xs-4:nth-child(3n+1) {
        clear: left; }
      .old .auto-clear .col-xs-6:nth-child(odd) {
        clear: left; } }
  @media (min-width: 768px) {
    .old .sidebar .sidebar-inner {
      display: block !important; }
    .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body {
      width: 100%; } }
  @media (min-width: 768px) and (max-width: 991px) {
    .old .auto-clear .col-sm-1:nth-child(12n+1) {
      clear: left; }
    .old .auto-clear .col-sm-2:nth-child(6n+1) {
      clear: left; }
    .old .auto-clear .col-sm-3:nth-child(4n+1) {
      clear: left; }
    .old .auto-clear .col-sm-4:nth-child(3n+1) {
      clear: left; }
    .old .auto-clear .col-sm-6:nth-child(odd) {
      clear: left; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    .old .auto-clear .col-md-1:nth-child(12n+1) {
      clear: left; }
    .old .auto-clear .col-md-2:nth-child(6n+1) {
      clear: left; }
    .old .auto-clear .col-md-3:nth-child(4n+1) {
      clear: left; }
    .old .auto-clear .col-md-4:nth-child(3n+1) {
      clear: left; }
    .old .auto-clear .col-md-6:nth-child(odd) {
      clear: left; }
    .old .mainbar .row .widget .widget-content .button-wrapper {
      width: auto;
      text-align: left; }
    .old form:not(.filter):not(.popup) .cts-form-group label {
      width: 33.33333333%;
      float: left; }
    .old form:not(.filter):not(.popup) .cts-form-group > div {
      width: 66.66666667% !important;
      float: left;
      padding-left: 1em; }
    .old form:not(.filter):not(.popup) .cts-form-group.inputonly {
      margin-bottom: 0 !important; }
      .old form:not(.filter):not(.popup) .cts-form-group.inputonly > div {
        width: 100% !important;
        padding-right: 1em !important;
        padding-left: 0 !important; }
      .old form:not(.filter):not(.popup) .cts-form-group.inputonly label {
        display: none !important; } }
  @media (min-width: 1200px) {
    .old .auto-clear .col-lg-1:nth-child(12n+1) {
      clear: left; }
    .old .auto-clear .col-lg-2:nth-child(6n+1) {
      clear: left; }
    .old .auto-clear .col-lg-3:nth-child(4n+1) {
      clear: left; }
    .old .auto-clear .col-lg-4:nth-child(3n+1) {
      clear: left; }
    .old .auto-clear .col-lg-6:nth-child(odd) {
      clear: left; }
    .old form:not(.filter):not(.popup) .cts-form-group label {
      width: 50%;
      float: left; }
    .old form:not(.filter):not(.popup) .cts-form-group > div {
      width: 50% !important;
      float: left;
      padding-left: 1em; }
    .old form:not(.filter):not(.popup) .cts-form-group.inputonly {
      margin-bottom: 0 !important; }
      .old form:not(.filter):not(.popup) .cts-form-group.inputonly > div {
        width: 100% !important;
        padding-right: 1em !important;
        padding-left: 0 !important; }
      .old form:not(.filter):not(.popup) .cts-form-group.inputonly label {
        display: none !important; }
    .old form.filter .cts-form-group {
      width: 25%;
      float: left;
      padding: 0 1em; }
      .old form.filter .cts-form-group.chkbox {
        width: auto; }
        .old form.filter .cts-form-group.chkbox label {
          width: 175px; }
      .old form.filter .cts-form-group > div {
        padding: 0; }
      .old form.filter .cts-form-group:nth-of-type(2n + 1) {
        clear: none; }
      .old form.filter .cts-form-group:nth-of-type(4n + 1) {
        clear: none; }
    .old .mainbar .row .widget .widget-content .image-preview {
      max-width: inherit; }
    .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body {
      width: 75%; }
    .old .image-preview {
      float: left;
      clear: both; }
    .old form.listboxkozepre .cts-form-group label {
      width: 25% !important; }
    .old form.listboxkozepre .cts-form-group > div.input-group {
      width: 75% !important; }
    .old .old #message-body #message-write-container #text, .old body #message-body #message-write-container #text, .old body body #message-body #message-write-container #text {
      width: 80%; } }
  @media (max-width: 1200px) {
    .old form.filter .cts-form-group.chkbox label {
      left: 35px;
      top: 4px; }
    .old form.filter .cts-form-group.chkbox > div {
      position: relative;
      bottom: 25px; } }
  @media (min-width: 1600px) {
    .old .mainbar .row .widget .widget-content .psycho-test .psycho-test-body {
      width: 60%; }
    .old .column-left {
      min-width: 16.7%; }
    .old form.listboxkozepre .cts-form-group label {
      width: 50% !important; }
    .old form.listboxkozepre .cts-form-group > div.input-group {
      width: 50% !important; } }

#directory #toolbarPopup {
  width: 877px;
  border: 1px solid #c8c8c8; }

#directory #defaultTab {
  width: 875px;
  border-left: 1px solid #c8c8c8;
  border-right: 1px solid #c8c8c8; }
  #directory #defaultTab ul li {
    border: none;
    min-width: 275px; }
    #directory #defaultTab ul li img {
      margin-right: 10px; }
  #directory #defaultTab ul .e-active {
    background: #BED5E6; }

.msgtools.Accept {
  background-image: url("../images/Uzenetek/elfogad.png");
  width: 32px;
  height: 32px;
  vertical-align: center;
  background-repeat: no-repeat; }

.msgtools.Back {
  background-image: url("../images/Uzenetek/Back.png");
  width: 32px;
  height: 32px;
  vertical-align: center;
  background-repeat: no-repeat; }

/* eszköznyilvántartó swal */
.assetRow label {
  text-align: left;
  margin-top: 5px;
  width: 51%; }

.assetRow input {
  width: auto; }

/* idegenbeli költségvetés flex */
.budgetPlanContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .budgetPlanContainer .budgetPlanItem {
    text-align: center;
    margin-bottom: 30px; }
    .budgetPlanContainer .budgetPlanItem .budgetPlanItemHeader {
      font-weight: bold; }
    .budgetPlanContainer .budgetPlanItem .budgetPlanInputWrapper {
      margin: 3px; }
    .budgetPlanContainer .budgetPlanItem button {
      max-width: 125px;
      margin-left: -1em; }

/* havi jelenléti összesítő flex */
.monthlyAttendanceContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 15px; }
  .monthlyAttendanceContainer .monthlyAttendanceItem {
    text-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .monthlyAttendanceContainer .monthlyAttendanceItem .monthlyAttendanceItemHeader {
      font-weight: bold;
      margin-top: 10px; }
    .monthlyAttendanceContainer .monthlyAttendanceItem .monthlyAttendanceItemInput {
      margin: 3px;
      padding: 5px;
      border: 1px solid lightgray;
      width: 215px; }

/* antroplompimpumetria Drinkwater input fix */
.drinkWaterContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center; }
  .drinkWaterContainer .drinkWaterItem {
    width: 170px;
    margin: 5px; }

/* sportoló módosítása fejléc  */
.athleteWidget {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }

#athleteMenu {
  position: absolute;
  left: -280px;
  top: 52px;
  background-color: #222d32;
  z-index: 100;
  width: 280px; }
  #athleteMenu a {
    color: #FFFFFF;
    text-decoration: none;
    display: block;
    -webkit-transition: all 0.5s linear;
    transition: all 0.5s linear; }
    #athleteMenu a:hover {
      color: #FFFFFF; }
    #athleteMenu a.disabled {
      background-color: #676767;
      cursor: not-allowed; }
      #athleteMenu a.disabled:hover {
        background-color: #676767; }
  #athleteMenu ul {
    padding: 0;
    margin: 0;
    list-style-type: none; }
    #athleteMenu ul li {
      list-style-type: none; }
      #athleteMenu ul li a {
        padding: 0.75em 1.5em;
        border-top: 1px solid #181f23;
        background-color: #222d32; }
        #athleteMenu ul li a:hover {
          background-color: #3174A8; }
  #athleteMenu.is-activate {
    left: 0; }

#printMenu {
  position: absolute;
  right: -280px;
  top: 52px;
  background-color: #222d32;
  z-index: 100;
  width: 280px;
  -webkit-transition: all 0.25s linear;
  transition: all 0.25s linear; }
  #printMenu a {
    color: #FFFFFF;
    text-decoration: none;
    display: block; }
    #printMenu a:hover {
      color: #FFFFFF; }
    #printMenu a.disabled {
      background-color: #3174A8;
      cursor: not-allowed; }
  #printMenu ul {
    padding: 0;
    margin: 0;
    list-style-type: none; }
    #printMenu ul li {
      list-style-type: none; }
      #printMenu ul li a {
        padding: 0.75em 1.5em;
        border-top: 1px solid #181f23;
        background-color: #222d32; }
        #printMenu ul li a:hover {
          background-color: #3174A8; }
  #printMenu.is-activate {
    right: 0; }

#userNavSM {
  display: none; }
